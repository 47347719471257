import useWindowDimensions from "../Hooks/useWindowDimensions";

const TextChart = (props) => {

  const { windowWidth } = useWindowDimensions();

  let pageHeader = "";
  let mainText = "";
  let pageSubHeader = "";

  let score = 0;
  let storageScore = props.variables.find(obj => obj.variable == "store");
  console.log("TextChart props", props);
  console.log("storageScore", storageScore);
  console.log("props.variables", props.variables);

  if (storageScore) {
    score = storageScore.score;
  }

  if (score == 7) {
    pageHeader = "You have unlocked a superpower — please write a book so other churches can learn from your expertise.";
    mainText = "A Lean church has enough storage space in key locations that rooms and spaces function effectively.";
  } else if (score == 8) {
    pageHeader = "You may not have enough storage or you may be storing the wrong stuff.";
    mainText = "The Lean Facility Indicator recommends an audit of your stored items for how frequently they are used.";
  } else if (score == 9) {
    pageHeader = "You may not have enough storage or you may be storing the wrong stuff.";
    mainText = "The Lean Facility Indicator recommends an audit of your stored items for how frequently they are used.";
  } else if (score == 10) {
    pageHeader = "Get the junk out!";
    mainText = "The Lean facility indicator recommends storing items that are not frequently used off-site.";
  }

  function brOrSpace() {
    if (windowWidth < 831) return " ";
    else return <br/>;
  }

  return (
    <div className="chart-container text-page">
      <h1 className="chart-header">{pageHeader}</h1>
      <div className="text-container">
        <div className="text-subcontainer">
          <p className="chart-main-text">{mainText}</p>
        </div>
      </div>
      
      {/* <p className="chart-subheader">A Lean Church needs to dedicate approx.<br/><span>{score}%</span> of its square footage to storage.</p> */}
      <p className="chart-subheader">A Lean Church needs to dedicate approx.{brOrSpace()}<strong>7%</strong> of its square footage to storage.</p>
    </div>
  );

}

export default TextChart;