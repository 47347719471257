import React, { useState, useEffect } from "react";
import { Add, Remove } from "@mui/icons-material";
import { Button } from "@mui/material";
import "../App.scss";


function Tabs(props) {
    
	const [isLoading, setIsLoading]   						= useState(true);

	const [toggleState, setToggleState] 					= useState(1);
	const [showMoreDetails, setShowMoreDetails] 	= useState(false);
	const [questions, setQuestions] 							= useState([]);
	const [sections, setSections] 								= useState([]);
	const [toggleMore, setToggleMore] 						= useState(0);	
	const [section1saligned, setSection1sAligned]	= useState([]);
	const [section1sout, setSection1sOut]					= useState([]);
	const [section2saligned, setSection2sAligned]	= useState([]);
	const [section2sout, setSection2sOut]					= useState([]);
	const [section3saligned, setSection3sAligned]	= useState([]);
	const [section3sout, setSection3sOut]					= useState([]);
	const [section4saligned, setSection4sAligned]	= useState([]);
	const [section4sout, setSection4sOut]					= useState([]);
	const [section5saligned, setSection5sAligned]	= useState([]);
	const [section5sout, setSection5sOut]					= useState([]);
	const[celebrateCurrentAreabtn, setcelebrateCurrentAreabtn] = useState(true);
	const[analyzePotentialAreabtn, setanalyPotentialAreabtn] = useState(true);


	useEffect(() => {
    updateContent(); 
  }, []);

	function getMostCommonAnswer(arr) {

		let count = {};
		let maxCount = 0;
		let mostCommonAns = null;
		
		for (let i = 0; i < arr?.length; i++) {
			const answer = arr?.[i]?.answer;
			count[answer] = (count[answer] || 0) + 1;
			
			if (count[answer] > maxCount) {
				maxCount = count[answer];
				mostCommonAns = answer;
			}
		}
		return mostCommonAns;
	}

	const updateContent = () => {

		let members 					= props.members;
		let questions 				= props.questions;
		let sections 					= props.sections;

		let sectionIds 		= [];
		sections.forEach((section) => {
			sectionIds.push(section.id);
		});

		console.log(questions);

		questions.forEach(question => {
			
			let answer1 				= 0;
			let answer2 				= 0;
			let answer3 				= 0;
			let answer4 				= 0;
			let answer5 				= 0;
			let aligned 				= false;
			let alignedAnswer 	= 0;
			let memAnswers 			= [];
			
			members.forEach(member => {
				let answers 			= member.answers.find(function(obj) { return obj.question_id == question.id; });
				memAnswers.push({
					name: member.fullName,
					answer: answers.answer
				});
				if(answers.answer == 1) { answer1++; }
				if(answers.answer == 2) { answer2++; }
				if(answers.answer == 3) { answer3++; }
				if(answers.answer == 4) { answer4++; }
				if(answers.answer == 5) { answer5++; }
			});

			let answerArray 		= [
				{id: 1, margin: answer1 / members.length},
				{id: 2, margin: answer2 / members.length},
				{id: 3, margin: answer3 / members.length},
				{id: 4, margin: answer4 / members.length},
				{id: 5, margin: answer5 / members.length}
			];
			answerArray.sort((a, b) => b.margin - a.margin);
			// if(answerArray[0].margin > 0.5) {
			// 	aligned 					= true;
			// 	alignedAnswer 		= answerArray[0].id;
			// }

			let zeroAnswers = 0;
			answerArray.forEach(mem => {
				if(mem.margin == 0) {
					zeroAnswers++;
				}
			});

			if(zeroAnswers > 3) {
				aligned = true;
			} else if(zeroAnswers > 2) {
				let newAnswerArray = answerArray.sort((a, b) => a.id - b.id);
				let memDiff = newAnswerArray.filter(function (obj) { return obj.margin != 0 && obj.id != 3 });
				// console.log(memDiff);
				if(memDiff.length > 1 && memDiff[1].id - memDiff[0].id == 1) {
					aligned = true;
				}
			}

			// question.aligned 		= aligned;
			// question.answerArray = answerArray;
			// question.alignedAns	= alignedAnswer;
			// question.members 		= memAnswers;
			// question.open 			= false;
			question.aligned 		= aligned;
			question.answerArray = answerArray;
			// question.alignedAns	= alignedAnswer;
			question.alignedAns	= getMostCommonAnswer(memAnswers);
			question.members 		= memAnswers;
			question.open 			= false;

		});

		console.log(questions);

		let section1Aligned		= questions.filter(function (obj) { return obj.sectionId == sectionIds[0] && obj.aligned; });
		setSection1sAligned(section1Aligned);
		let section1Out				= questions.filter(function (obj) { return obj.sectionId == sectionIds[0] && !obj.aligned; });
		setSection1sOut(section1Out);
		let section2Aligned		= questions.filter(function (obj) { return obj.sectionId == sectionIds[1] && obj.aligned; });
		setSection2sAligned(section2Aligned);
		let section2Out				= questions.filter(function (obj) { return obj.sectionId == sectionIds[1] && !obj.aligned; });
		setSection2sOut(section2Out);
		let section3Aligned		= questions.filter(function (obj) { return obj.sectionId == sectionIds[2] && obj.aligned; });
		setSection3sAligned(section3Aligned);
		let section3Out				= questions.filter(function (obj) { return obj.sectionId == sectionIds[2] && !obj.aligned; });
		setSection3sOut(section3Out);
		let section4Aligned		= questions.filter(function (obj) { return obj.sectionId == sectionIds[3] && obj.aligned; });
		setSection4sAligned(section4Aligned);
		let section4Out				= questions.filter(function (obj) { return obj.sectionId == sectionIds[3] && !obj.aligned; });
		setSection4sOut(section4Out);		
		let section5Aligned		= questions.filter(function (obj) { return obj.sectionId == sectionIds[4] && obj.aligned; });
		setSection5sAligned(section5Aligned);
		let section5Out				= questions.filter(function (obj) { return obj.sectionId == sectionIds[4] && !obj.aligned; });
		setSection5sOut(section5Out);

		setQuestions(questions);
		setSections(sections);
		setIsLoading(false);
		
	}

	const toggleTab = (index) => {
		setToggleState(index);
	};

	const toggleDetails = (id) => {
		if(id == toggleMore) {
			setToggleMore(0);
		} else {
			setToggleMore(id);
		}
	}

	const toggleMoreDisplay = (id) => {
		if(id == toggleMore) {
			return { display: "block" }
		} else {
			return { display: "none" }
		}
	}

	const toggleMoreIcon = (id) => {
		if(id == toggleMore) {
			return <Remove />
		} else {
			return <Add />
		}
	}

	const celebrateCurrentAreaHandler = (e) => {
		e.preventDefault();
    setcelebrateCurrentAreabtn(!celebrateCurrentAreabtn);
	}

	const analyzePotentialAreaHandler = (e) => {
		e.preventDefault();
		setanalyPotentialAreabtn(!analyzePotentialAreabtn);
	}

	const showAnswerText = (answer, questionId) => {
		// console.log(answer);
		let question			= questions.find(function (obj) { return obj.id == questionId; });
		// console.log(question.answers);
		let answerVal			= question.answers.find(function (obj) { return obj.value == answer; });
		if(answerVal && answerVal.desktop_text) {
			<div className="answer false">{answerVal.desktop_text}</div>
		}
		
		if(answer == 1) {
		return <div className="answer alignright false">STRONGLY DISAGREE</div>
		} else if(answer == 2) {
		return <div className="answer alignright false">DISAGREE</div>
		} else if(answer == 3) {
		return <div className="answer alignright neutral">NEUTRAL</div>
		} else if(answer == 4) {
		return <div className="answer alignright true">AGREE</div>
		} else if(answer == 5) {
		return <div className="answer alignright true">STRONGLY AGREE</div>
		}
	}

	const showIndividualAnswerText = (answer, questionId) => {
		
		let question			= questions.find(function (obj) { return obj.id == questionId; });
		let answerType 		= "green";
		// console.log(question);

		if(question.answers.length == 2) {
			if(answer == 1) { answerType = "red"; }
			else if(answer == 2) { answerType = "green"; }
		} else if(question.answers.length == 3) {
			if(answer == 1) { answerType = "red"; }
			else if(answer == 2) { answerType = "orange"; }
			else if(answer == 3) { answerType = "green"; }
		} else if(question.answers.length == 4) {
			if(answer == 1) { answerType = "red"; }
			else if(answer == 2) { answerType = "red"; }
			else if(answer == 3) { answerType = "green"; }
			else if(answer == 4) { answerType = "green"; }
		} else if(question.answers.length == 5) {
			if(answer == 1) { answerType = "red"; }
			else if(answer == 2) { answerType = "red"; }
			else if(answer == 3) { answerType = "orange"; }
			else if(answer == 4) { answerType = "green"; }
			else if(answer == 5) { answerType = "green"; }
		}
		let answerVal			= question.answers.find(function (obj) { return obj.value == answer; });
		
		if(answerVal && answerVal.desktop_text) {
			if(answerType == "red") {
				return <div className="answer false">{answerVal.desktop_text.replace(/<br\/\s*\\?>/g, "\r\n")}</div>
			} else if(answerType == "orange") {
				return <div className="answer neutral">{answerVal.desktop_text.replace(/<br\/\s*\\?>/g, "\r\n")}</div>
			} else if(answerType == "green") {
				return <div className="answer true">{answerVal.desktop_text.replace(/<br\/\s*\\?>/g, "\r\n")}</div>
			}
		}
		
	}

	function removeHtmlTags(text) {
		return text.replace(/<\/?[^>]+(>|$)/g, " ");
	}

	if (isLoading) {
		return null;
	}

	// making this new array because we have to remove the last section "feedback" from dashboard page tabs 
	const sectionsArr = [...sections];
	// sectionsArr?.pop();
	console.log("sectionsArr", sectionsArr);
	console.log("sections", sections);

	return (
		<div className="container-tabs">
			<div className="tab-buttons">
			{
				sectionsArr.map((data, index) => {
					let toggleIndex = index + 1;
					return <button key={index} className={toggleState === toggleIndex ? "active" : ""} onClick={() => toggleTab(toggleIndex)}>{data.section}</button>
				}
			)}
      </div>

			<div className="tabs-content">
				<div className={toggleState === 1 ? "content  active-content" : "content"}>

					{
						section1saligned.length > 0 ?
							<div className="align-details" onClick = {celebrateCurrentAreaHandler}>
								<div className="green-container">
								<p className="green">Where you're aligned</p>
								{celebrateCurrentAreabtn ? <Remove className="celebrateCurrentAreabtn" onClick = {celebrateCurrentAreaHandler}/> : <Add className="celebrateCurrentAreabtn" onClick = {celebrateCurrentAreaHandler}/>}
								</div>
								{/* <p className="green">Celebrate current areas of great alignment</p> */}

								{celebrateCurrentAreabtn &&	section1saligned.map((data, index) => (
									// section1saligned.map((data, index) => (
										<div key={index} className="answered-detail no-wrap">
											{removeHtmlTags(data.question)}
											{showAnswerText(data.alignedAns, data.id)}

										</div>
									))
								}
							</div>
						: null
					}
                        
					{
						section1sout.length > 0 ?
						<div className="align-details analyze" >
							<div className="red-container" onClick= {analyzePotentialAreaHandler}>
							<p className="red">Where you're not yet aligned</p>
							{analyzePotentialAreabtn? <Remove className="analyzePotentialAreabtn" onClick= {analyzePotentialAreaHandler}/> : <Add className="analyzePotentialAreabtn" onClick={analyzePotentialAreaHandler}/>}
							</div>
							{/* <p className="red">Analyze potential areas for better alignment</p> */}
							{analyzePotentialAreabtn && section1sout.map((data, index) => (
								// section1sout.map((data, index) => (
									<div key={index} className="answered-detail" onClick={() => toggleDetails(data.id)} style={{ cursor: "pointer" }}>
										<p>{removeHtmlTags(data.question)}</p>
										<Button className="more-toggle">{toggleMoreIcon(data.id)}</Button>
										<div className="more-details" style={ toggleMoreDisplay(data.id) }>
											{
												data.members.map((member, index) => (
													<div key={index} className="more-row"><p>{member.name}</p> {showIndividualAnswerText(member.answer, data.id)}</div>											
												))
											}	
										</div>
									</div>
								))
							}
						</div>
						: null
					}

				</div>

				<div className={toggleState === 2 ? "content  active-content" : "content"}>
					{
						section2saligned.length > 0 ?
							<div className="align-details" onClick = {celebrateCurrentAreaHandler}>
						    <div className="green-container">
								<p className="green">Where you're aligned</p>
								{celebrateCurrentAreabtn ? <Remove className="celebrateCurrentAreabtn" onClick = {celebrateCurrentAreaHandler}/> : <Add className="celebrateCurrentAreabtn" onClick = {celebrateCurrentAreaHandler}/>}
								</div>
								{/* <p className="green">Celebrate current areas of great alignment</p> */}

								{celebrateCurrentAreabtn &&	section2saligned.map((data, index) => (
								
									// section2saligned.map((data, index) => (
										<div key={index} className="answered-detail no-wrap">
											{removeHtmlTags(data.question)}
											{showAnswerText(data.alignedAns, data.id)}
										</div>
									))
								}
							</div>
						: null
					}
                        
					{
						section2sout.length > 0 ?
						<div className="align-details analyze">
							<div className="red-container" onClick= {analyzePotentialAreaHandler}>
							<p className="red">Where you're not yet aligned</p>
							{analyzePotentialAreabtn? <Remove className="analyzePotentialAreabtn" onClick= {analyzePotentialAreaHandler}/> : <Add className="analyzePotentialAreabtn" onClick={analyzePotentialAreaHandler}/>}
							</div>
							{/* <p className="red">Analyze potential areas for better alignment</p> */}
							{analyzePotentialAreabtn && section2sout.map((data, index) => (
							
								// section2sout.map((data, index) => (
									<div key={index} className="answered-detail" onClick={() => toggleDetails(data.id)} style={{ cursor: "pointer" }}>
										<p>{removeHtmlTags(data.question)}</p>
										<Button className="more-toggle">{toggleMoreIcon(data.id)}</Button>
										<div className="more-details" style={ toggleMoreDisplay(data.id) }>
											{
												data.members.map((member, index) => (
													<div key={index} className="more-row"><p>{member.name}</p> {showIndividualAnswerText(member.answer, data.id)}</div>											
												))
											}	
										</div>
									</div>
								))
							}
						</div>
						: null
					}
				</div>

				<div className={toggleState === 3 ? "content  active-content" : "content"}>
					{
						section3saligned.length > 0 ?
							<div className="align-details" onClick = {celebrateCurrentAreaHandler}>
        					<div className="green-container">
								<p className="green">Where you're aligned</p>
								{celebrateCurrentAreabtn ? <Remove className="celebrateCurrentAreabtn" onClick = {celebrateCurrentAreaHandler}/> : <Add className="celebrateCurrentAreabtn" onClick = {celebrateCurrentAreaHandler}/>}
								</div>
								{/* <p className="green">Celebrate current areas of great alignment</p> */}

						{celebrateCurrentAreabtn &&	section3saligned.map((data, index) => (
									// section3saligned.map((data, index) => (
										<div key={index} className="answered-detail no-wrap">
											{removeHtmlTags(data.question)}
											{showAnswerText(data.alignedAns, data.id)}
										</div>
									))
								}
							</div>
						: null
					}
                        
					{
						section3sout.length > 0 ?
						<div className="align-details analyze">
							<div className="red-container" onClick= {analyzePotentialAreaHandler}>
							<p className="red">Where you're not yet aligned</p>
							{analyzePotentialAreabtn? <Remove className="analyzePotentialAreabtn" onClick= {analyzePotentialAreaHandler}/> : <Add className="analyzePotentialAreabtn" onClick={analyzePotentialAreaHandler}/>}
							</div>
							{/* <p className="red">Analyze potential areas for better alignment</p> */}
							{analyzePotentialAreabtn && section3sout.map((data, index) => (
							
								// section3sout.map((data, index) => (
									<div key={index} className="answered-detail" onClick={() => toggleDetails(data.id)} style={{ cursor: "pointer" }}>
										<p>{removeHtmlTags(data.question)}</p>
										<Button className="more-toggle">{toggleMoreIcon(data.id)}</Button>
										<div className="more-details" style={ toggleMoreDisplay(data.id) }>
											{
												data.members.map((member, index) => (
													<div key={index} className="more-row"><p>{member.name}</p> {showIndividualAnswerText(member.answer, data.id)}</div>											
												))
											}	
										</div>
									</div>
								))
							}
						</div>
						: null
					}
				</div>
                
				<div className={toggleState === 4 ? "content  active-content" : "content"}>
					{
						section4saligned.length > 0 ?
							<div className="align-details" onClick = {celebrateCurrentAreaHandler}>
							<div className="green-container">
								<p className="green">Where you're aligned</p>
								{celebrateCurrentAreabtn ? <Remove className="celebrateCurrentAreabtn" onClick = {celebrateCurrentAreaHandler}/> : <Add className="celebrateCurrentAreabtn" onClick = {celebrateCurrentAreaHandler}/>}
								</div>
								{/* <p className="green">Celebrate current areas of great alignment</p> */}

								{celebrateCurrentAreabtn &&	section4saligned.map((data, index) => (
									// section4saligned.map((data, index) => (
										<div key={index} className="answered-detail no-wrap">
											{removeHtmlTags(data.question)}
											{showAnswerText(data.alignedAns, data.id)}
										</div>
									))
								}
							</div>
						: null
					}
                        
					{
						section4sout.length > 0 ?
						<div className="align-details analyze" >
							<div className="red-container" onClick= {analyzePotentialAreaHandler}>
							<p className="red">Where you're not yet aligned</p>
							{analyzePotentialAreabtn? <Remove className="analyzePotentialAreabtn" onClick= {analyzePotentialAreaHandler}/> : <Add className="analyzePotentialAreabtn" onClick={analyzePotentialAreaHandler}/>}
							</div>
							{/* <p className="red">Analyze potential areas for better alignment</p> */}
							{analyzePotentialAreabtn && section4sout.map((data, index) => (
							
								// section4sout.map((data, index) => (
									<div key={index} className="answered-detail" onClick={() => toggleDetails(data.id)} style={{ cursor: "pointer" }}>
										<p>{removeHtmlTags(data.question)}</p>
										<Button className="more-toggle">{toggleMoreIcon(data.id)}</Button>
										<div className="more-details" style={ toggleMoreDisplay(data.id) }>
											{
												data.members.map((member, index) => (
													<div key={index} className="more-row"><p>{member.name}</p> {showIndividualAnswerText(member.answer, data.id)}</div>											
												))
											}	
										</div>
									</div>
								))
							}
						</div>
						: null
					}
				</div>
				
				<div className={toggleState === 5 ? "content  active-content" : "content"}>
					{
						section5saligned.length > 0 ?
							<div className="align-details" onClick = {celebrateCurrentAreaHandler}>
							<div className="green-container">
								<p className="green">Where you're aligned</p>
								{celebrateCurrentAreabtn ? <Remove className="celebrateCurrentAreabtn" onClick = {celebrateCurrentAreaHandler}/> : <Add className="celebrateCurrentAreabtn" onClick = {celebrateCurrentAreaHandler}/>}
								</div>
								{/* <p className="green">Celebrate current areas of great alignment</p> */}

								{celebrateCurrentAreabtn &&	section5saligned.map((data, index) => (
										<div key={index} className="answered-detail no-wrap">
											{removeHtmlTags(data.question)}
											{showAnswerText(data.alignedAns, data.id)}
										</div>
									))
								}
							</div>
						: null
					}
                        
					{
						section5sout.length > 0 ?
						<div className="align-details analyze" >
							<div className="red-container" onClick= {analyzePotentialAreaHandler}>
							<p className="red">Where you're not yet aligned</p>
							{analyzePotentialAreabtn? <Remove className="analyzePotentialAreabtn" onClick= {analyzePotentialAreaHandler}/> : <Add className="analyzePotentialAreabtn" onClick={analyzePotentialAreaHandler}/>}
							</div>
							{/* <p className="red">Analyze potential areas for better alignment</p> */}
							{analyzePotentialAreabtn && section5sout.map((data, index) => (
							
								// section4sout.map((data, index) => (
									<div key={index} className="answered-detail" onClick={() => toggleDetails(data.id)} style={{ cursor: "pointer" }}>
										<p>{removeHtmlTags(data.question)}</p>
										<Button className="more-toggle">{toggleMoreIcon(data.id)}</Button>
										<div className="more-details" style={ toggleMoreDisplay(data.id) }>
											{
												data.members.map((member, index) => (
													<div key={index} className="more-row"><p>{member.name}</p> {showIndividualAnswerText(member.answer, data.id)}</div>											
												))
											}	
										</div>
									</div>
								))
							}
						</div>
						: null
					}
				</div>
			</div>
		</div>
	);
}

export default Tabs;