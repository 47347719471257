import { useState, useRef, useEffect, useMemo } from 'react';
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";

import VideoThumbnail from '../Assets/Images/video-thumbnail.png';
// import VideoThumbnailMobile from '../Assets/Images/video-thumbnail-mobile.png';
// import PlayButton from '../Assets/Images/play-button.png';
import playBtn from '../Assets/Images/play-btn.png';
import playBtnHover from '../Assets/Images/play-btn-hover.png';
import Clock from '../Assets/Images/clock.png';
import { Close, Pause, PlayArrow } from "@mui/icons-material";
import useWindowDimensions from '../Hooks/useWindowDimensions';
import '../App.scss';
import { Button } from '@mui/material';

const Start = props => {

  const { windowWidth } = useWindowDimensions();

  const [isLoading, setIsLoading] = useState(true);

  const [searchParams, setSearchParams] = useSearchParams();
  const [isPlayingVideo, setIsPlayingVideo] = useState(false);
  const [isVidPaused, setIsVidPaused] = useState(false);
  // const [startPlaying, setStartPlaying]   = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [playBtnImg, setPlayBtnImg] = useState(playBtn);

  const vidRef = useRef(null);
  const introElmRef = useRef(null);
  const videoElmRef = useRef(null);
  const location = useLocation();
  const display = searchParams.get("display");
  const navigate = useNavigate();



  // useEffect(() => {
  //   if(display === "preview") {
  //     console.log("changed background img for embedded preview display...");
  //     document.body.style.background = `#242424 url(${introBgImg}) repeat`;
  //   } else {
  //     document.body.style.background = `#414042`;
  //   }
  // }, [display]);

  const createAssessment = () => {

    // TODO: change the pageId according to what the page ID is for this assessment
    const pageId = 695;

    let leaderId = 0;
    let userId = 0;
    let isMember = false;
    if (searchParams?.get("leader")) {
      leaderId = searchParams?.get("leader");
      userId = searchParams?.get("user");
      isMember = true;
    }

    const clientId = process.env.REACT_APP_CLIENT_ID;
    fetch(process.env.REACT_APP_API_URI + '/v3/user/assessment', {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ clientId, isMember, leaderId, userId })
    }).then(response => {
      return response.json();
    }).then(data => {
      if (data.status === "success") {
        // navigate("/intro/" + data.id + "?" + searchParams, { replace: true });
        navigate("/explainer/" + data.id + "/" + pageId + "?" + searchParams, { replace: true });
        console.log("data", data);
      } else {
        // setIsSaving(false);
        // setOpenSnackbar(true);
      }
    }).catch((err) => console.error("Failed to create new assessment...", err));
  }


  const howItWorksLink = () => {
    if (searchParams == "") {
      return "/how-it-works";
    } else {
      return "/how-it-works?" + searchParams;
    }
  }
  // console.log(searchParams.get("display"));

  const assessmentLink = () => {
    return "/assessment?" + searchParams;
  }

  const preAssessmentQuestionsLink = () => {
    navigate("/pre-additional-questions?" + searchParams);
  }

  const introPageLink = () => {
    createAssessment();
    // navigate("/intro/" + id + "?" + searchParams);
  }

  const playVideo = (e) => {

    // if(windowWidth > 830) {
    //   const introElm = introElmRef.current;
    //   const videoElm = videoElmRef.current;

    //   introElm.style.cssText = "width: 0%; padding: 0; overflow: hidden;"
    //   videoElm.style.cssText = "width: 100%; margin: 0 auto; background-color: #E5E5E5; border-radius: 0;";
    //   // if(display === "preview") {
    //   //   videoElm.style.background = `#242424 url(${introBgImg}) repeat`;
    //   // } else videoElm.style.backgroundColor = "#435B63";
    // }
    vidRef.current.play();
    setIsPlayingVideo(true);
    setIsVidPaused(false);
    // if(!startPlaying) {
    //   setStartPlaying(true);
    // }
  }

  const pauseVideo = (e) => {
    // vidRef.current.pause();
    // setPlayingVideo(false);
    vidRef.current.pause();
    setIsVidPaused(true);
    setPlayBtnImg(playBtn);

    // if(windowWidth > 830) {
    //   const introElm = introElmRef.current;
    //   const videoElm = videoElmRef.current;

    //   introElm.style.cssText = "width: 46%; padding: 0px 3%; overflow: hidden;";
    //   videoElm.style.cssText = "width: 48%; margin: 0 auto; background-color: #435B63; border-top-left-radius: 10px; border-bottom-left-radius: 10px;";
    //   // if(display === "preview") {
    //   //   videoElm.style.background = `#242424 url(${introBgImg}) repeat`;
    //   // } else videoElm.style.backgroundColor = "#435B63";
    // }
    setIsPlayingVideo(false);
  }

  const handlePlayPause = () => {
    if (isPlayingVideo) {
      vidRef.current.pause();
      setIsVidPaused(true);
      setPlayBtnImg(playBtn);
    } else {
      vidRef.current.play();
      setIsVidPaused(false);
    }
    setIsPlayingVideo(!isPlayingVideo);
  };

  const handleTimeUpdate = () => {
    setCurrentTime(vidRef.current.currentTime);
  };

  const handleSeek = (e) => {
    const newTime = e.target.value;
    vidRef.current.currentTime = newTime;
    setCurrentTime(newTime);
  };

  const handleVideoPlaybackEnd = () => {
    setIsPlayingVideo(false);
    setIsVidPaused(false);

    // if(windowWidth > 830) {
    //   const introElm = introElmRef.current;
    //   const videoElm = videoElmRef.current;

    //   introElm.style.cssText = "width: 46%; padding: 0px 3%; overflow: hidden;";
    //   videoElm.style.cssText = "width: 48%; margin: 0 auto; background-color: #435B63; border-top-left-radius: 10px; border-bottom-left-radius: 10px;";

    //   // if(display === "preview") {
    //   //   videoElm.style.background = `#242424 url(${introBgImg}) repeat`;
    //   // } else videoElm.style.backgroundColor = "#435B63";
    // }
    // showThumbnail();
    showPlayButton();
  }

  const showThumbnail = useMemo(() => {

    if (!isPlayingVideo && !isVidPaused) {
      return <img alt="video thumbnail" className="thumbnail" src={VideoThumbnail} style={{ opacity: '1' }}
      // onClick={(e) => pauseVideo(e)} 
      />;
    } else {
      // return <img alt="video thumbnail" className="thumbnail" src={VideoThumbnail} style={{ opacity: '0' }} onClick={(e) => pauseVideo(e)} />;
      return null;
      // if(startPlaying) {
      //   return null
      // } else {
      //   return <img alt="video thumbnail" className="thumbnail" src={VideoThumbnail} />
      // }
    }
    // }, [isPlayingVideo, startPlaying]);
  }, [isPlayingVideo, isVidPaused]);

  const showPlayButton = () => {
    if (isPlayingVideo) {
      return null
    } else {
      return <img alt="play button" className="playbtn" src={playBtnImg} onClick={(e) => playVideo(e)} onMouseEnter={() => setPlayBtnImg(playBtnHover)} onMouseLeave={() => setPlayBtnImg(playBtn)} />
    }
  }

  function formatTime(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    if (!seconds) return "0:00";
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  }

  useEffect(() => {
    console.log('location',location)
    const app = document.querySelectorAll('.App');

    if(location.pathname='/start'){
      document.body.className = 'change-background'
      document.className = 'change-background'
      app.forEach((element) => {
        element.classList.add('background-filter');
      });
    }
    else{
      document.body.className = ''
    }

    return () => {
      document.body.classList.remove('change-background');
    };
  }, [location])

  const vidsrcURL = "https://40parables-assets.s3.amazonaws.com/bleat-Parable-Dollar-on-Mission-Assessment-Intro.mp4";

  return (
    <div className="container start">
      <div className="video" ref={videoElmRef}> {/*display:none*/}
        <video ref={vidRef} controls={false}
          onEnded={handleVideoPlaybackEnd}
          onPlay={() => setIsPlayingVideo(true)}
          onPause={() => {
            setIsPlayingVideo(false);
            setIsVidPaused(true);
            showPlayButton();
          }}
          onTimeUpdate={handleTimeUpdate}
        >

          <source src={vidsrcURL} type="video/mp4" />
        </video>
        {/* custom video controls */}
        {isPlayingVideo && <div className="custom-controls">
          <button onClick={handlePlayPause}>
            {isPlayingVideo ? <Pause /> : <PlayArrow />}
          </button>
          <input className="custom-slider"
            type="range" min="0" max={vidRef.current ? vidRef.current.duration : 0}
            value={currentTime} onChange={handleSeek}
          />
          <span>
            {formatTime(Math.floor(currentTime))} /{' '}
            {formatTime(Math.floor(vidRef.current ? vidRef.current.duration : 0))}
          </span>
        </div>}
        {/* custom video controls */}
        {/* {showThumbnail()} */}
        {showThumbnail} {/* using useMemo */}
        {showPlayButton()}

        {isPlayingVideo && <Close className="close-video" style={{
          position: "absolute", height: "20px", width: "20px", color: "#fefefede", opacity: 0.85, zIndex: 99, padding: "15px", top: "5px", cursor: "pointer",
          right: windowWidth > 1100 ? "15px" : "5px"
        }} onClick={(e) => pauseVideo(e)} />}

      </div>
      {/* {windowWidth < 830 && <div style={{ backgroundColor: "#00252A", height: "15px" }}></div>} */}
      <div className="intro" ref={introElmRef}>
        {/* <h1>Is your church facility keeping you from fulfilling your calling to the fullest?</h1> */}
        <h1>Compare your church facility with hundreds of thriving churches nation-wide in this 2-part peer assessment.</h1>
        {/* <p className='intro-para'>Stack up against hundreds of thriving churches nation-wide</p> */}
        {/* <div className="completion">See how you compare* with similarly-sized American churches</div> */}
        <ol type="1" className="intro-points-list">
          {
            searchParams.get("leader") ? <>
              <li> <span className='bold-txt'>Part 1:</span> 5 Benchmarking questions that reveal how your church compares to peer churches </li>
              <li> <span className='bold-txt'>Part 2:</span> 21 Alignment questions that reveal how aligned you are with your leadership team </li>
            </> : <>
              <li> <span className='bold-txt'>Part 1:</span> 5 Benchmarking questions that reveal how your church compares to peer churches </li>
              <li> <span className='bold-txt'>Part 2:</span> 21 Alignment questions that reveal how aligned you are with your leadership team </li>
            </>
          }
        </ol>

        <div className="completion">
          {/* <div className='btn-container'> */}
          {/* <Link to={assessmentLink()} className="cta">Get Started</Link> */}
          {/* <Button className="cta" onClick={() => introPageLink()}>FIND OUT</Button> */}
          <Button className="cta" onClick={() => introPageLink()}>{
            windowWidth > 831 ? "Test and see" : "TEST AND SEE"
          }</Button>
          {/* </div> */}
          <div className='time-estimate'>
            <div className='img-container'><img className='clock-icon' src={Clock} alt="estimated time" /></div><span className='time-txt'>{searchParams.get("leader") ? "4 min" : "4 min"}</span>
          </div>
        </div>


        {/* <div className='para-container'>
          <p className='para'>*We can help you compare your church's data to that of about 10,000 churches
          in the US through our church engagement research.</p>
        </div> */}
      </div>

    </div>
  );
}

export default Start;
