import { useContext, useEffect, useState, useRef } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { TextField, Button, FormControl, Select, MenuItem, InputLabel, CircularProgress } from "@mui/material";
import {ExpandMore} from '@mui/icons-material';
import AdditionalQuestionContext from "../Store/AdditionalQuestionContext";

const FirstForm = () => {

	const { id, pageId } = useParams();
	const [searchParams, setSearchParams] = useSearchParams();
	const navigate = useNavigate();
	const addCtx = useContext(AdditionalQuestionContext);
	
	const selectServicesRef = useRef(null);
	const selectSpacesRef = useRef(null);
	
	const [onFocusIpLabel1, setOnFocusIpLabel1] = useState(false);
	const [onFocusIpLabel2, setOnFocusIpLabel2]	= useState(false);
	const [acerage, setAcerage] 								= useState("");
	const [attendance, setAttendance] 					= useState("");
	const [services, setServices] 							= useState("");
	const [spaces, setSpaces] 									= useState("");
	const [spacesError, setSpacesError]					= useState("");
	const [spacesValid, setSpacesValid]					= useState("");
	const [classRooms, setClassRooms] 					= useState("");
	const [formQuestions, setFormQuestions]			= useState("");
	const [attendanceError, setAttendanceError]	= useState(false);
	const [acreageError, setAcreageError] 			= useState(false);
	const [classError, setClassError] 					= useState(false);
	const [servicesError, setServicesError] 		= useState(false);
	const [attendanceValid, setAttendanceValid] = useState(true);
	const [acreageValid, setAcreageValid] 			= useState(true);
	const [classValid, setClassValid] 					= useState(true);
	const [isSendingData, setIsSendingData] 		= useState(false);
	const [isMember, setIsMember] 							= useState(false);

	useEffect(() => {
		if (searchParams.get("leader")) {
			setIsMember(true);
		}
	}, [searchParams]);


	useEffect(() => {
		const updateForm1CtxDetails = (fieldName, value) => {
      if (value !== addCtx.form1Details?.[fieldName]) {
        addCtx.addForm1Details(prev => ({ ...prev, [fieldName]: value }));
      }
    };

    updateForm1CtxDetails("acerage", acerage);
    updateForm1CtxDetails("attendance", attendance);
		updateForm1CtxDetails("services", services);
		updateForm1CtxDetails("spaces", spaces);
		updateForm1CtxDetails("classRooms", classRooms);

  }, [acerage, attendance, classRooms, services, spaces]);

	useEffect(() => {
    let form1Details = addCtx.form1Details;

    if (form1Details?.acerage?.length)		setAcerage(form1Details.acerage);
    if (form1Details?.attendance?.length)	setAttendance(form1Details.attendance);
		if (form1Details?.services)						setServices(form1Details.services);
		if (form1Details?.spaces)							setSpaces(form1Details.spaces);
		if (form1Details?.classRooms?.length)	setClassRooms(form1Details.classRooms);

  }, [addCtx.form1Details]);
	
	console.log("addCtx.form1Details", addCtx.form1Details);
	console.log("formQuestions", formQuestions);

	const fetchResults = () => {
		// fetch(process.env.REACT_APP_API_URI + "/v1/assessment/" + id, {
		fetch(process.env.REACT_APP_API_URI + "/v3/user/assessment/" + id + "/results", {
		  method: "GET",
		  mode: "cors",
		  headers: { "Content-Type": "application/json" },
		})
		  .then((response) => {
			return response.json();
		  })
		  .then((data) => {
			if (data.status === "success") {
				if (data.isMember || data.isMember === "yes") {
					setIsMember(data.isMember);	
				}
			} else {
			  // setOpenSnackbar(true);
			}
		  });
	};


	useEffect(()=>{
		fetchResults()
	},[])

	useEffect(() => {
		
		const getFormQuestions = () => {
			fetch(process.env.REACT_APP_API_URI + '/v3/user/assessment/' + id + '/pages/' + pageId, {
				method: 'GET',
				mode: 'cors',
				headers: { 'Content-Type': 'application/json' }
			}).then(response => {
				return response.json();
			}).then(data => {
				if(data.status == "success") {
					console.log(data);
					setFormQuestions(data?.data.questions);
					// TODO: update & test after API is updated
					if (data?.data?.isMember) {
						setIsMember(data?.data?.isMember);
					}
					// setIsLoading(false);
				} else {
					// setOpenSnackbar(true);
				}
			}).catch((err) => {
				console.error("Error while fetching form questions...", err);
			});
		}
		
		getFormQuestions();

	}, [id]);

	useEffect(() => {
		const getLeadersScoresForMember = () => {
			
			const leaderHash = searchParams.get("leader");
			fetch(process.env.REACT_APP_API_URI + '/v3/user/assessment/' + leaderHash + '/variableScores', {
				method: 'GET', mode: 'cors',
				headers: { 'Content-Type': 'application/json' }
			}).then(response => {
				return response.json();
			}).then(data => {
				if (data.status == "success") {
					console.log("leader variable scores", data);
					const variables = data?.variables;
					const acreage 					= variables?.find((obj) => obj.variable === "acres")?.score || "";
					const weekendAttendance = variables?.find((obj) => obj.variable === "watt")?.score || "";
					const numOfServices 		= variables?.find((obj) => obj.variable === "serv")?.score || 1;
					const classrooms 				= variables?.find((obj) => obj.variable === "class")?.score || "";
					const gatheringSpaces 	= variables?.find((obj) => obj.variable === "gather")?.score || 0;
					setAcerage(acreage);
					setAttendance(weekendAttendance);
					setServices(numOfServices);
					setClassRooms(classrooms);
					setSpaces(gatheringSpaces);
				}
			}).catch((err) => {
				console.error("Error while fetching leader's scores...", err);
			});
	
		}

		if (isMember) {
			getLeadersScoresForMember();
		}
	}, [id, isMember, searchParams]);


	const submitFirstForm = (e) => {
		e.preventDefault();
		
		const jsonBody = { 
      pageId: pageId,
			questions: [
        {
          "id": 856,
          "answer": parseInt(acerage),
          "answerTxt": null
        },
        {
					"id": 857,
          "answer": parseInt(attendance),
          "answerTxt": null
        },
        {
					"id": 858,
          "answer": parseInt(services),
          "answerTxt": null
        },
        {
					"id": 859,
          "answer": parseInt(classRooms),
          "answerTxt": null
        },
				{
					"id": 889,
					"answer": parseInt(spaces),
					"answerTxt": null
				}
      ],
    }
		
		setIsSendingData(true);
		fetch(process.env.REACT_APP_API_URI + '/v3/user/assessment/' + id, {
      method: 'PUT',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(jsonBody)
    }).then(response => {
      return response.json();
    }).then(data => {
      if(data.status === "success") {
				setIsSendingData(false);
        navigate("/questions/" + id + "/698?" + searchParams, { replace: true });
        console.log("data", data);
      } else {
        // setIsSaving(false);
        // setOpenSnackbar(true);
      }
    }).catch((err) => {
			console.error("Failed to send form details...", err);
			setIsSendingData(false);
		});


	}

	const handleSubmit = (e) => {
		e.preventDefault();
		// submitFirstForm(e);
		if(attendance >= 0 && acerage >= 0 && classRooms >= 0 && services >= 0 && spaces >= 0) {
			submitFirstForm(e);
		} else {
			showErrors();
		}
	}

	const showErrors = () => {

		if(isNaN(attendance) || parseInt(attendance) <= 0) {
			setAttendanceError(true);
		} else {
			setAttendanceError(false);
		}

		if(isNaN(classRooms) || parseInt(classRooms) <= 0) {
			setClassError(true);
		} else {
			setClassError(false);
		}

		if(isNaN(acerage) || parseInt(acerage) <= 0) {
			setAcreageError(true);
		} else {
			setAcreageError(false);
		}

		if(isNaN(services) || parseInt(services) <= 0 || services.toString().trim() == "") {
			setServicesError(true);
		} else {
			setServicesError(false);
		}
		
		if(isNaN(spaces) || parseInt(spaces) < 0 || spaces.toString().trim() == "") {
			setSpacesError(true);
		} else {
			setSpacesError(false);
		}

		// if(attendance.length > 7 || attendance.length < 5 ) {
		// 	setAttendanceValid(false);
		// }

	}

	const showHintText = (field) => {
		if(field == "acreage") {
			if(acreageError) {
				return <div className="error-text">Please enter your valid acreage</div>
			}
			if(!acreageValid) {
				// return <div className="error-text">Maximum avg. weekly attendance can range from 100 to 100,000.</div>
			}
		}

		if(field == "attendance") {
			if(attendanceError) {
				return <div className="error-text">Please enter your weekend attendance</div>
			}
			if(!attendanceValid) {
				// return <div className="error-text">Invalid ZIP code.</div>
			}
		}

		if(field == "class") {
			if(classError) {
				return <div className="error-text">Please enter the number of adult classrooms</div>
			}
			if(!classValid) {
				// return <div className="error-text">Invalid ZIP code.</div>
			}
		}

		if(field == "services") {
			if(servicesError) {
				return <div className="error-text">Please enter the number of services you host</div>
			}
			// if(!classValid) {
			// 	return <div className="error-text">Invalid ZIP code.</div>
			// }
		}
		
		if(field == "spaces") {
			if(spacesError) {
				return <div className="error-text">Please enter the number of gathering spaces</div>
			}
			// if(!classValid) {
			// 	return <div className="error-text">Invalid ZIP code.</div>
			// }
		}
	}

	const removeHintText = (field, e) => {
		if(e.target.closest(".form-detail")) {
			document.body.querySelectorAll(".input-title").forEach(elm => elm.style.color = "#4A4A4A")
			e.target.closest(".form-detail").querySelector(".input-title").style.color = "#ED2028";
		}

		if(field == "acreage") {
			setAcreageError(false);
			setAcreageValid(true);
		}

		if(field == "attendance") {
			setAttendanceError(false);
			setAttendanceValid(true);
		}

		if(field == "services") {
			setServicesError(false);
			// setservicesValid(true);
		}
		
		if(field == "spaces") {
			setSpacesError(false);
			setSpacesValid(true);
		}

		if(field == "class") {
			setClassError(false);
			setClassValid(true);
		}
	}
  const checkDisabled = () => {
    if(attendance  && services && acerage  && classRooms && spaces?.toString().length > 0) {
      return false
		} else {
			return true
		}
  }

	return (
		<div className="firstform-container" onClick={() => {
			selectServicesRef.current.blur();
			selectSpacesRef.current.blur();
		}}>
			<div className="firstform">
				<h1>Before we begin, tell us a little about your church</h1>
				<p className="subtext">If you have multiple sites, please fill out this form for your main campus only.</p>
			</div>
			<div className="form">
				<div className="form-detail Acreage">
					<p className="input-title">Acreage<span className="required-field">*</span></p>
					<TextField className="form-details-text" placeholder="E.g. 5" value={acerage} onFocus={(e) => removeHintText("acreage", e)} onChange={(e) => setAcerage(e.target.value)}></TextField>
				  {showHintText("acreage")}
				</div>

				<div className="form-detail attendance">
					<p className="input-title">Weekend Attendance<span className="required-field">*</span></p>
					<TextField className="form-details-text" placeholder="E.g. 5000" value={attendance} onFocus={(e) => removeHintText("attendance", e)} onChange={(e) => setAttendance(e.target.value)}></TextField>
				  {showHintText("attendance")}
				</div>

				<div className="form-detail no-of-services">
					<p className="input-title">Number of Services<span className="required-field">*</span></p>
					{/* <TextField className="form-details-text" placeholder="E.g. 1" value={Services} onFocus={(e) => removeHintText("weeklyAttendance", e)} onChange={(e) => setServices(e.target.value)}></TextField>
				  {showHintText("weeklyAttendance")} */}
				  <FormControl fullWidth>
						<InputLabel style={{display: onFocusIpLabel1 || services != '' ? "none" : "block"}} className="inputlabel-no-of-services" id="demo-simple-select-label">E.g. 1</InputLabel>
						<Select value={services} ref={selectServicesRef} onFocus={(e) => {
							setOnFocusIpLabel1(true);
							removeHintText("services", e);
						}} 
						IconComponent={()=>(<ExpandMore/>)}
						onBlur={()=>{setOnFocusIpLabel1(false)}} onChange={(e) => setServices(e.target.value)} labelId="demo-simple-select-label" >
							<MenuItem value={1}>1</MenuItem>
							<MenuItem value={2}>2</MenuItem>
							<MenuItem value={3}>3</MenuItem>
							<MenuItem value={4}>4</MenuItem>
						</Select>
					</FormControl>
					{showHintText("services")}
				</div>

				<div className="form-detail number-of-adult-classrooms">
					<p className="input-title">Number of Adult Classrooms<span className="required-field">*</span></p>
					<TextField className="form-details-text" placeholder="E.g. 10" value={classRooms} onFocus={(e) => removeHintText("class", e)} onChange={(e) => setClassRooms(e.target.value)}></TextField>
				  {showHintText("class")}
				</div>

				<div className="form-detail no-of-services full-width">
					<p className="input-title">Number of extra gatherings spaces:<span className="required-field">*</span><span style={{ fontWeight: 300 }}>(Gym, Fellowship Hall, Youth Room)</span></p>
				  <FormControl fullWidth>
						<InputLabel style={{display: onFocusIpLabel2 || spaces?.toString().trim() != '' ? "none" : "block"}} 
						className="inputlabel-no-of-services" id="demo-simple-select-label">E.g. 1</InputLabel>
						<Select value={spaces} ref={selectSpacesRef} onFocus={(e) => {
							setOnFocusIpLabel2(true);
							removeHintText("spaces", e);
						}} 
						IconComponent={()=>(<ExpandMore/>)}
						onBlur={()=>{setOnFocusIpLabel2(false)}} onChange={(e) => setSpaces(e.target.value)} labelId="demo-simple-select-label" >
							<MenuItem value={0}>0</MenuItem>
							<MenuItem value={1}>1</MenuItem>
							<MenuItem value={2}>2</MenuItem>
							<MenuItem value={3}>3</MenuItem>
						</Select>
					</FormControl>
					{showHintText("spaces")}
				</div>

			</div>
			<div className="cta">
				{isSendingData ? 
					<Button className="cta-btn" style={{ backgroundColor: "#FFDD61", color: "#343333", minWidth: "100px", padding: "10px 15px" }}>
						<CircularProgress style={{ color: "#343333" }} size={20} />
					</Button>
				: <Button className="cta-btn" disabled={checkDisabled()} onClick={(e) => handleSubmit(e)}>NEXT</Button>}

				{/* <Button className="cta-btn" onClick={(e) => submitFirstForm(e)}>LET'S GO</Button> */}
			</div>
			
			{isMember && <p className="member-disclaimer">*Your form is pre-filled according to your leader's responses. You may edit these if required.</p>}

			{/* 
			<div className="firstform-footer">
				<p className="firstform-footer-text">This benchmarking data will help visualize how you compare with similarly-sized American churches.</p>
			</div> */}
		</div>
	)
}

export default FirstForm;
