import React,{ useEffect } from 'react';
import { useNavigate, useSearchParams, useParams, useLocation } from "react-router-dom";
import Clock from '../Assets/Images/clock.png'
import { Button } from '@mui/material'

const GenericPage = () => {

  let navigate = useNavigate();
  const { id, pageId } = useParams();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const nextPage = () => {
    navigate("/first-form/" + id + "/" + pageId + "?" + searchParams, { replace: true });
  }

  useEffect(() => {
    console.log('location',location)
    const app = document.querySelectorAll('.App');

    if(location.pathname='/start'){
      document.body.className = 'change-background'
      document.className = 'change-background'
      app.forEach((element) => {
        element.classList.add('background-filter');
      });
    }
    else{
      document.body.className = ''
    }

    return () => {
      document.body.classList.remove('change-background');
    };
  }, [location])
  

  return (
    <div className='generic-msg-container'>
        <div className="main-text-container">
          <p className='text'>
            The <span>Lean Church Facility Model</span> is an effective and efficient model in which the environments of a church facility are designed to advance the Mission and serve the community.
          </p>
          <p className='subtext'>How does your church facility compare?</p>
        </div>
        <div className="cta">
            <div className="cta-btn">
              <Button onClick={nextPage}>Find out</Button>
            </div>
        </div>
        {/* <div className='time-estimate'>
            <div className='img-container'><img className='clock-icon' src={Clock} alt="estimated time" /></div><span>{searchParams.get("leader") ? "4 min" : "4 min"}</span>
          </div> */}
    </div>
  )
}

export default GenericPage;
