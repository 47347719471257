export const sectionsText = {
  interiors: [
    "Have intentional conversations with your congregants to understand how they perceive your facility and hear their ideas on how your space could support your mission better.",
    "Hire an expert to survey your facility and explore minor or major interior modification possibilities to help maximize your facility utilization for your mission based on your budget.",
  ],
  relevance: [
    "Assess your church facilities to keep up with the latest trends and technological advancements to remain relevant and efficient in every season.",
    "Survey how your community interacts with your space; does it reflect your mission goals? Or worse, does it distract from your goals?",
  ],
  community: [
    "Work with your mayor and local leaders of surrounding cities to see how your church facilities can serve your community even better.",
    "Invite groups of new and experienced church members to a discovery session to understand how your facility accommodates them and how it might be falling short.",
  ],
  sameScores: [
    "Survey how your community interacts with your space; does it reflect your mission goals? Or worse, does it distract from your goals?",
    "Invite groups of new and experienced church members to a discovery session to understand how your facility accommodates them and where it might be falling short.",
    "Hire an expert to survey your facility and explore minor or major interior modification possibilities to help maximize your facility utilization for your mission based on your budget.",
  ],
};
