import React from "react";
import { Link, useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import analyzeIcon from "../Assets/Images/assess.png";
import alignmentIcon from "../Assets/Images/benchmark.png";
import directionIcon from "../Assets/Images/calibrate.png";
import Testimonial from "./Testimonial";

const InviteInfo = (props) => {
  const { id } = useParams();

  return (
    <div className="invite-container">
      <div className="invite-container-spacer">
        <h3>Invite your team to take this assessment</h3>
        <p>
          Now, invite your team to take this same assessment and view everyone’s results to compare your church against a <span className="bold-text">Lean Church</span> and your responses with your leadership team’s.
        </p>

        <div className="invite-reasons">
          <div className="reason">
            <img src={analyzeIcon} alt="insights icon" />
            <br />
            <div className="height30"></div>
            <span>Assess</span>
            <br />
            Evaluate your facility’s efficiency in key areas
          </div>

          <div className="reason">
            <img src={alignmentIcon} alt="alignment icon" />
            <br />
            <div className="height30"></div>
            <span>Benchmark</span>
            <br />
            Check how you compare with thriving churches nationwide
          </div>

          <div className="reason">
            <img src={directionIcon} alt="direction icon" />
            <br />
            <div className="height30"></div>
            <span>Calibrate</span>
            <br />
            Align on action based on carefully assessed data{" "}
          </div>
        </div>

        <div className="cta-btn">
          <Link to={"/invite-team/" + id} target="_blank">
            <Button>INVITE YOUR TEAM</Button>
          </Link>
        </div>

        {/* <Testimonial /> */}
      </div>

      {/* <div className="testimonial-invite">
        <p>Invite your team to take this test along with you and begin to align around the vision for your church's mission!</p>
        <div className="cta-btn">
          <Link to={"/invite-team/" + id} target="_blank">
            <Button>INVITE YOUR TEAM</Button>
          </Link>
        </div>
      </div> */}
    </div>
  );
};

export default InviteInfo;
