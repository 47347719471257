import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";

import prevArrow from "../Assets/Images/prev-arrow.svg";
import nextArrow from "../Assets/Images/next-arrow.svg";
import testimonialImage from "../Assets/Images/testimonial.jpg";
// import scheduledImage from "../Assets/Images/walter-kim.png";
import quotationImage from "../Assets/Images/quotation.svg";
import upArrowImg from "../Assets/Images/up-arrow.png";
import telephoneIcon from '../Assets/Images/telephone.png'
import analysisIcon from '../Assets/Images/compass.png'
import personIcon from '../Assets/Images/person.png'
import InviteInfo from "../Components/InviteInfo";
import Testimonial from "../Components/Testimonial";
import Resources from "../Components/Resources";
import DonutChart from "../Components/DonutChart";
import SendInvite from "../Components/SendInvite";

import ColumnChart from "../Components/ColumnChart";
import CustomStackedColumnChart from "../Components/CustomStackedColumnChart";
import TextChart from "../Components/TextChart";

import { Button, Accordion, AccordionSummary, AccordionDetails, Box, Typography, LinearProgress, CircularProgress } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Add, ExpandLess, ExpandMore, Remove } from "@mui/icons-material";

import useWindowDimensions from '../Hooks/useWindowDimensions';
import { sectionsText } from "../globalFunctions";
import "../App.scss";

const CustomExpandIcon = () => {
  return (
    <Box
      sx={{
        ".Mui-expanded & > .collapsIconWrapper": {
          display: "none"
        },
        ".expandIconWrapper": {
          display: "none"
        },
        ".Mui-expanded & > .expandIconWrapper": {
          display: "block"
        }
      }}
    >
      <div className="expandIconWrapper"><Remove /></div>
      <div className="collapsIconWrapper"><Add /></div>
    </Box>
  );
};

const Results = (props) => {
  
  let navigate = useNavigate();
  const { id } = useParams();
  
  const { windowWidth } = useWindowDimensions();
  const [isLoading, setIsLoading] = useState(true);
  const [isMember, setIsMember] = useState(false);
  const [apiData, setApiData] = useState();
  const [name, setName] = useState("");
  const [score, setScore] = useState(0);
  const [sections, setSections] = useState([]);
  const [subScore1, setSubScore1] = useState(0);
  const [subScore2, setSubScore2] = useState(0);
  const [subScore3, setSubScore3] = useState(0);
  const [subScore4, setSubScore4] = useState(0);
  const [subScore5, setSubScore5] = useState(0);
  const [subScores, setSubScores] = useState([0, 0, 0, 0, 0]);
  const [activeScoreSection, setActiveScoreSection] = useState();
  const [accordionSections, setAccordionSections] = useState([]);
  const [chartsData, setChartsData] = useState();
  const [variables, setVariables] = useState([]);
  const [seats, setSeats] = useState(0);
  const [leanSeats, setLeanSeats] = useState(0);
  const [attendance, setAttendance] = useState(0);
  const [attendanceLean, setAttendanceLean] = useState(0);

	
  useEffect(() => {
    // document.body.style.backgroundColor = "#435B63";
    fetchResults();
  }, []);

  useEffect(()=>{
    console.log("id",id)
    console.log("isMember",isMember)
  },[id,isMember])

  useEffect(() => {
    const sectionsAndSubScores = sections?.map((sec, idx) => ({ section: sec.section, score: subScores[idx] }));
    const sortedSectionsAndSubScores = [...sectionsAndSubScores]?.sort((a, b) => a.score - b.score);
    setActiveScoreSection(sortedSectionsAndSubScores?.[0]?.section);
  }, [sections, subScores]);

  useEffect(() => {
    // basic check for API data reliability 
    if(apiData?.score > 0 && apiData?.status === "success") {

      const score1 = apiData?.chart1Values?.find(val => val.name === apiData?.selected1Answer)?.y;
      const score2 = apiData?.chart2Values?.find(val => val.name === apiData?.selected2Answer)?.y;
      const score3 = apiData?.chart3Values?.find(val => val.name === apiData?.selected3Answer)?.y;
      const score4 = apiData?.chart4Values?.find(val => val.name === apiData?.selected4Answer)?.y;
      const score5 = apiData?.chart5Values?.find(val => val.name === apiData?.selected5Answer)?.y;

      setChartsData([
        { title: "The community around us is aware of our mission.", 
          order: 1, values: apiData?.chart1Values, answer: { name: apiData?.selected1Answer, y: score1} },
        { title: "How many engagement opportunities did you host in the past year open to the larger community?", 
          order: 2, values: apiData?.chart2Values, answer: { name: apiData?.selected2Answer, y: score2} },
        { title: "Throughout last year, how many community members participated in these engagement opportunities?", 
          order: 3, values: apiData?.chart3Values, answer: { name: apiData?.selected3Answer, y: score3} },
        { title: "Over time, our church's engagement with our community has been...", 
          order: 4, values: apiData?.chart4Values, answer: { name: apiData?.selected4Answer, y: score4} },
        { title: "Our community frequently utilizes our church's building facilities and programs.", 
          order: 5, values: apiData?.chart5Values, answer: { name: apiData?.selected5Answer, y: score5} },
      ]);
    }
  }, [apiData]);

  console.log("chartsData", chartsData);

  // to animate subScores on results page 
  useEffect(() => {
    // this settimeout is causing donutcharts selected pie enlargement to disappear so commented it out 
    // const timer = setTimeout(() => {
    //   setSubScores([subScore1, subScore2, subScore3, subScore4, subScore5]);
    // }, 250);
    // return () => {
    //   clearTimeout(timer);
    // };
    
    setSubScores([subScore1, subScore2, subScore3, subScore4, subScore5]);

  }, [subScore1, subScore2, subScore3, subScore4, subScore5]);

  const fetchResults = () => {
    // fetch(process.env.REACT_APP_API_URI + "/v1/assessment/" + id, {
    fetch(process.env.REACT_APP_API_URI + "/v3/user/assessment/" + id + "/results", {
      method: "GET",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.status === "success") {
          console.log(data);
          
          setApiData(data);
          setName(data.name);
          setScore(data.score);
          setSections(data.sections);
          setAccordionSections(data.sections?.map(sec => ({ ...sec, isExpanded: false })));
          setSubScore1(data.subScore1);
          setSubScore2(data.subScore2);
          setSubScore3(data.subScore3);
          setSubScore4(data.subScore4);
          setSubScore5(data.subScore5);
          setIsMember(data.isMember);
          setVariables(data.variables);

          let seatsVar = data.variables.find(obj => obj.variable == "seats");
          if(seatsVar) {
            setSeats(seatsVar.score);
          }
          let seatsleanVar = data.variables.find(obj => obj.variable == "seatleanscore");
          if(seatsleanVar) {
            setLeanSeats(Math.round(seatsleanVar.score));
          }
          let attendanceVar = data.variables.find(obj => obj.variable == "watt");
          if(attendanceVar) {
            setAttendance(attendanceVar.score);
          }
          let attendanceleanVar = data.variables.find(obj => obj.variable == "wattleanscore");
          if(attendanceleanVar) {
            setAttendanceLean(Math.round(attendanceleanVar.score));
          }
          // console.log(data);

          setIsLoading(false);

        } else {
          // setOpenSnackbar(true);
        }
      });
  };


  const sectionsAndSubScores = sections?.map((sec, idx) => ({ section: sec.section, score: subScores[idx] }));
  // remove the last Feedback section from Results and Dashboard
  // sectionsAndSubScores.pop();
  console.log("sectionsAndSubScores", sectionsAndSubScores);
  
  const sortedSectionsAndSubScores = [...sectionsAndSubScores]?.sort((a, b) => a.score - b.score);
  console.log("sortedSectionsAndSubScores", sortedSectionsAndSubScores);

  // get the object with the highest score
  let highestScoreObject = sortedSectionsAndSubScores?.[(sortedSectionsAndSubScores?.length) - 1];

  // if there are duplicate highest scores, find the first occurrence
  if (highestScoreObject?.score === sortedSectionsAndSubScores[(sortedSectionsAndSubScores?.length) - 2]?.score) {
    let currentIndex = sortedSectionsAndSubScores?.length - 2;
    while (currentIndex >= 0 && sortedSectionsAndSubScores?.[currentIndex]?.score === highestScoreObject.score) {
      highestScoreObject = sortedSectionsAndSubScores[currentIndex];
      currentIndex--;
    }
  }

  // get the object with the lowest score
  let lowestScoreObject = sortedSectionsAndSubScores?.[0];

  // if there are duplicate lowest scores, find the first occurrence
  if (lowestScoreObject?.score === sortedSectionsAndSubScores[1]?.score) {
    let currentIndex = 2;
    while (currentIndex < sortedSectionsAndSubScores.length && sortedSectionsAndSubScores[currentIndex]?.score === lowestScoreObject.score) {
      lowestScoreObject = sortedSectionsAndSubScores[currentIndex];
      currentIndex++;
    }
  }


  console.log("sortedSectionsAndSubScores[sortedSectionsAndSubScores?.length - 1]", sortedSectionsAndSubScores[sortedSectionsAndSubScores?.length - 1]);
  console.log("sortedSectionsAndSubScores", sortedSectionsAndSubScores);
  console.log("highestScoreObject", highestScoreObject);
  console.log("lowestScoreObject", lowestScoreObject);

  const showTitleText = () => {
    if (score <= 25) {
      return <h1><span className="user-name">{name}</span>, your facility seems to be meeting only a few of your mission goals.</h1>
    } else if (score > 25 && score <= 50) {
      return <h1><span className="user-name">{name}</span>, your facility seems to be meeting only some of your mission goals.</h1>
    } else if (score > 50 && score <= 75) {
      return <h1><span className="user-name">{name}</span>, your facility seems to be meeting many of your mission goals!</h1>
    } else if (score > 75 && score <= 100){
      return <h1><span className="user-name">{name}</span>, your facility seems to be meeting most of your mission goals!</h1>
    }  
  };

  const showScoreText = () => {
    // const section = sectionsText?.[sortedSectionsAndSubScores[0]?.section?.toLowerCase()];
    const section = sectionsText?.[lowestScoreObject?.section?.toLowerCase()];
    const allScoresSame = sortedSectionsAndSubScores?.every(obj => obj.score === sortedSectionsAndSubScores?.[0]?.score);
    
    if(allScoresSame) {
      return <div className="result-text">
        <p style={{ margin: "10px auto 15px" }}>We're glad to see your facility is maintaining a <strong>balanced focus across all areas</strong> of requirements.</p>
        <p style={{ margin: "10px auto 5px" }}>Here are some tips to take your facility utilization and impact to the next level:</p>
        <div style={{ margin: "5px auto 10px 10px" }}>{sectionsText?.sameScores?.map((text, idx) => <p key={idx} style={{ margin: "2px auto" }}>{idx + 1}.&nbsp;&nbsp;{text}</p>)}</div>
      </div>
    } else {
        return <div className="result-text">
            <div className="note"> Keep up the good work on the <span style={{fontWeight: "700"}}>{highestScoreObject?.section?.toLowerCase()}</span> front, and pay special attention to your <span style={{fontWeight: "700"}}>{lowestScoreObject?.section?.toLowerCase()}</span>.</div>
            <div className="tips-container">
              <p className="tips-subtext">Here are some tips:</p>
              <ul className="tips-list">
                {section?.map((text, idx) => <li key={idx} style={{ margin: "2px auto" }}>{idx + 1}.&nbsp;&nbsp;&nbsp;{text}</li>)}
              </ul>
            </div>
        </div>
    }

    

    console.log('sectionsText',sectionsText)
    // if (score <= 25) {
    //   return (<div className="result-text">
    //       <div className="note"> Keep up the good work on the <span style={{fontWeight: "700"}}>{highestScoreObject?.section?.toLowerCase()}</span> front, and pay special attention to your <span style={{fontWeight: "700"}}>{lowestScoreObject?.section?.toLowerCase()}</span>.</div>
    //       <div className="tips-container">
    //         <p className="tips-subtext">Here are some tips:</p>
    //         <ul className="tips-list">
    //           {/* <li>1. Have intentional conversations with your congregants to understand how they perceive your facility and hear their ideas on how your space could reflect your mission better.</li>
    //           <li>2. Hire an expert to survey your facility and explore minor or major interior modification possibilities to help maximize your facility utilization for your mission based on your budget.</li> */}
    //           {section?.map((text, idx) => <li key={idx} style={{ margin: "2px auto" }}>{idx + 1}.&nbsp;&nbsp;{text}</li>)}
    //         </ul>
    //       </div>
    //   </div>);
    // } else if (score > 25 && score <= 50) {
    //   return (<div className="result-text">
    //       <div className="note"> Keep up the good work on the <span style={{fontWeight: "700"}}>{highestScoreObject?.section?.toLowerCase()}</span> front, and pay special attention to your <span style={{fontWeight: "700"}}>{lowestScoreObject?.section?.toLowerCase()}</span>.</div>
    //       <div className="tips-container">
    //         <p className="tips-subtext">Here are some tips:</p>
    //         <ul className="tips-list">
    //           {/* <li>1. Have intentional conversations with your congregants to understand how they perceive your facility and hear their ideas on how your space could reflect your mission better.</li>
    //           <li>2. Hire an expert to survey your facility and explore minor or major interior modification possibilities to help maximize your facility utilization for your mission based on your budget.</li> */}
    //           {section?.map((text, idx) => <li key={idx} style={{ margin: "2px auto" }}>{idx + 1}.&nbsp;&nbsp;{text}</li>)}
    //         </ul>
    //       </div>
    //   </div>);
    // } else if (score > 50 && score <= 75) {
    //   return (<div className="result-text">
    //       <div className="note"> Keep up the good work on the <span style={{fontWeight: "700"}}>{highestScoreObject?.section?.toLowerCase()}</span> front, and pay special attention to your <span style={{fontWeight: "700"}}>{lowestScoreObject?.section?.toLowerCase()}</span>.</div>
    //       <div className="tips-container">
    //         <p className="tips-subtext">Here are some tips:</p>
    //         <ul className="tips-list">
    //           {/* <li>1. Have intentional conversations with your congregants to understand how they perceive your facility and hear their ideas on how your space could reflect your mission better.</li>
    //           <li>2. Hire an expert to survey your facility and explore minor or major interior modification possibilities to help maximize your facility utilization for your mission based on your budget.</li> */}
    //           {section?.map((text, idx) => <li key={idx} style={{ margin: "2px auto" }}>{idx + 1}.&nbsp;&nbsp;{text}</li>)}
    //         </ul>
    //       </div>
    //   </div>);
    // }
    // else if(score > 75 && score <= 100) {
    //   return (<div className="result-text">
    //       <div className="note"> Keep up the good work on the <span style={{fontWeight: "700"}}>{highestScoreObject?.section?.toLowerCase()}</span> front, and pay special attention to your <span style={{fontWeight: "700"}}>{lowestScoreObject?.section?.toLowerCase()}</span>.</div>
    //       <div className="tips-container">
    //         <p className="tips-subtext">Here are some tips:</p>
    //         <ul className="tips-list">
    //           {/* <li>1. Have intentional conversations with your congregants to understand how they perceive your facility and hear their ideas on how your space could reflect your mission better.</li>
    //           <li>2. Hire an expert to survey your facility and explore minor or major interior modification possibilities to help maximize your facility utilization for your mission based on your budget.</li> */}
    //           {section?.map((text, idx) => <li key={idx} style={{ margin: "2px auto" }}>{idx + 1}.&nbsp;&nbsp;{text}</li>)}
    //         </ul>
    //       </div>
    //   </div>); 
    // }

  };

  const handleSectionAccordionExpansion = (idx) => {
    const newSections = [...accordionSections];
    newSections[idx].isExpanded = !newSections[idx].isExpanded;
    setAccordionSections(newSections);
  }

  const getSectionBottomBar = () => {
    //  activeScoreSection == sections[0]?.section
    if (activeScoreSection == accordionSections[0]?.section) {
      return <div className="section-bottom-text">
        <Accordion key={accordionSections[0]?.section} className="section-accordion" expanded={accordionSections[0]?.isExpanded || false}>
          <AccordionSummary expandIcon={null} onClick={() => handleSectionAccordionExpansion(0)} 
          className="section-accordion-summary">
            <p style={{ fontWeight: 600 }}>Looking to align your congregation with your church's mission? Here are 6 questions you must ask:</p>
            <div className="custom-accordion-control" style={{ display: accordionSections[0]?.isExpanded ? "none" : "flex", textAlign: "center" }}><span>READ MORE</span><ExpandMore /></div>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <p>Churches need to have a clear mission to help guide the leadership and to align its people. It must be easy to understand, that it is clear what actions to take to help fulfill the mission, and that people understand why it is important that the church fulfill its mission.</p>
              <p>One reason your congregants don't align with your church's mission is confusion or ambiguity regarding necessary actions.</p>
              <p>Here is an example of an unclear mission statement: “I want to lose weight.” It doesn't convey crucial information like how much weight is to be lost or why it is important to lose it.</p>
              <p>Here is an example of a clear mission statement: “My mission is to lose 15 pounds in the next 3 months because I want to be healthy enough to play with my kids.”</p>
              <p>Here are 6 questions to ask yourself, your leadership team, and some congregants to evaluate your mission statement:</p>
              <ul>
                <li>Can you recite your church's mission statement from memory?</li>
                <li>What are the key parts of your church's mission statement?</li>
                <li>How would you grade your church in fulfilling its mission?</li>
                <li>In what ways do you see your church fulfilling its mission?</li>
                <li>Where do you feel like you fit in your church's mission? Simply put, why do you think God brought you to your church to help fulfill its mission?</li>
                <li>What actions do you need to take to help the church fulfill its mission?</li>
              </ul>
              <div className="custom-accordion-control" style={{ display: accordionSections[0]?.isExpanded ? "flex" : "none", textAlign: "center" }} 
                onClick={() => handleSectionAccordionExpansion(0)}><span>READ LESS</span><ExpandLess /></div>

            </div>
          </AccordionDetails>
        </Accordion>
        <img style={{ marginLeft: '10.5%' }} src={upArrowImg} alt="up arrow" className="up-arrow" />
      </div>
    } else if (activeScoreSection == accordionSections[1]?.section) {
      // activeScoreSection == sections[1]?.section
      return <div className="section-bottom-text">
        <Accordion key={accordionSections[1]?.section} className="section-accordion" expanded={accordionSections[1]?.isExpanded || false}>
          <AccordionSummary expandIcon={null} onClick={() => handleSectionAccordionExpansion(1)} 
          className="section-accordion-summary">
            <p style={{ fontWeight: 600 }}>Ensure your church is spending its money on the right things and maximizing its impact!</p>
            <div className="custom-accordion-control" style={{ display: accordionSections[1]?.isExpanded ? "none" : "flex", textAlign: "center" }}><span>READ MORE</span><ExpandMore /></div>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <p>This is one of the biggest challenges for churches — with limited resources and increasing costs, how do you ensure your church is spending its money on the right things?</p>
              <p>It starts with planning and prioritizing your spending. That's another way of saying budgeting.</p>
              <p>But before you tune this out because you don't like budgets...</p>
              <p>A budget is simply a plan to focus your spending on the key items that will advance your mission and vision. A budget can be freeing rather than restrictive because it helps you prioritize your dollars and helps you know how much wiggle room (dollar-wise) you have for each ministry.</p>
              <p>Struggling with financial planning? Watch our <a href="https://liveyourparable.com/assess-your-churchs-financial-health/?pa_utm_source=assessment&pa_utm_medium=recommendations" target="_blank" rel="noreferrer">webinar</a> on Assessing your Church's Financial Health or read our <a href="https://liveyourparable.com/understand-your-church-financial-statements-make-a-greater-impact-5-key-areas-to-assess/?pa_utm_source=assessment&pa_utm_medium=recommendations" target="_blank" rel="noreferrer">blog</a>.</p>
              <div className="custom-accordion-control" style={{ display: accordionSections[1]?.isExpanded ? "flex" : "none", textAlign: "center" }} 
                onClick={() => handleSectionAccordionExpansion(1)}><span>READ LESS</span><ExpandLess /></div>
            </div>
          </AccordionDetails>
        </Accordion>
        <img style={{ marginLeft: '47.5%' }} src={upArrowImg} alt="up arrow" className="up-arrow" />
      </div>
    } else if (activeScoreSection == accordionSections[2]?.section) {
      // activeScoreSection == sections[2]?.section
      return <div className="section-bottom-text">
        <Accordion key={accordionSections[2]?.section} className="section-accordion" expanded={accordionSections[2]?.isExpanded || false}>
          <AccordionSummary expandIcon={null} onClick={() => handleSectionAccordionExpansion(2)} 
          className="section-accordion-summary">
            <p style={{ fontWeight: 600 }}>$80 billion is expected to be lost to fraud by churches - ensure you have an operating system that protects you from becoming a victim!</p>
            <div className="custom-accordion-control" style={{ display: accordionSections[2]?.isExpanded ? "none" : "flex", textAlign: "center" }}><span>READ MORE</span><ExpandMore /></div>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <p>Few things will disrupt a church more than the mishandling of money. Cases of fraud lead to a lack of trust in the leadership of the church. People stop giving and may even leave the church. Worse, the aftermath of fraud can distract leadership from being on their mission.</p>
              <p>Many pastors believe that they are not a target of fraud and that their staff and volunteers are trustworthy. Unfortunately, fraud within churches is on the rise. Brotherhood Mutual Insurance estimates that <a href="https://www.brotherhoodmutual.com/resources/safety-library/risk-management-articles/administrative-staff-and-finance/finances/fraudsters-target-churches/" target="_blank" rel="noreferrer">fraud in churches is expected to reach $80 billion by 2025!</a></p>
              <p>In 2019, they reported that more money was lost to fraud than was dedicated to supporting worldwide mission work. It's tragic to think that churches are expected to lose so much money that could otherwise be invested in missions for the Kingdom.</p>
              <p>But the good news is that you can greatly reduce the risk of fraud at your church by implementing sound controls and working with an accountant that understands churches.</p>
              <p>Our <a href="https://liveyourparable.com/free-download/?pa_utm_source=assessment&pa_utm_medium=recommendations" target="_blank" rel="noreferrer">Church Financial Toolkit</a> will help you understand the 7 key systems every church needs to have a healthy and thriving financial operating system.</p>

              <div className="custom-accordion-control" style={{ display: accordionSections[2]?.isExpanded ? "flex" : "none", textAlign: "center" }} 
                onClick={() => handleSectionAccordionExpansion(2)}><span>READ LESS</span><ExpandLess /></div>

            </div>
          </AccordionDetails>
        </Accordion>
        <img style={{ marginLeft: '83.5%' }} src={upArrowImg} alt="up arrow" className="up-arrow" />
      </div>
    }
  };

  const showProgressBarText = (scoreValue) => {
    if (scoreValue >= 95) {
      return "Enjoying health";
    } else if (scoreValue >= 80 && scoreValue < 95) {
      return "Improving health";
    } else if (scoreValue >= 70 && scoreValue < 80) {
      return "Pursuing health";
    } else if (scoreValue < 70) {
      return "Battling unhealthiness";
    }
  }

  const showInvite = () => {
    if (isMember) return null;
    else if (props?.showInvite === false) return null;
    else return ( <InviteInfo /> )
  };

  const showTeamMember = () => {
    if (isMember) {
      return (
        <div className="team-container">
          <h3 className="team-member-thank-you-box">Thank you for taking this assessment! Please get in touch with your leader for the next step.</h3>
          {/* <br />
          <br /> */}
        </div>
      );
    } else {
      return null;
    }
  };

  const showTestimonial = () => {
    if (!isMember) {
      return <Testimonial />;
    } else {
      // return null;
      return <Testimonial />;
    }
  };

  const showResources = () => {
    return <Resources />
  }

  const sendInvite = () => {
    return <SendInvite />
  }

  const getLeftMargin = () => {
    if (windowWidth < 651 && score > 97) {
      return { left: 98 + "%" };
    } else if (windowWidth < 651 && score < 3) {
      return { left: 2 + "%" };
    } else {
      return { left: Math.round(score) + "%" };
    }
    // return { left: Math.round(score) + "%" };
  };

  const getBarTextLeft = (score) => {
    if (window.innerWidth < 650 && score > 80) {
      return { left: "-170px" }
    }
    return { left: "-100px" }
  }

  const showSchedule = () => {
    if (isMember) {
      return null;
    } else {
      return (
        <div className="schedule-container">
          <div className="avatar">
            {/* <img src={scheduledImage} /> */}
          </div>
          <h3>Get on a call to plan your next steps</h3>
          <p>Our ministry-savvy design experts work with you to create a comprehensive roadmap that sets expectations for everyone. We create custom solutions based on the unique needs of your congregation, ensuring your ability to focus on the message and not your building.</p>
          <div className="info-text-container">
            <div className="schedule-call info-child">
              <div className="telephone-icons">
                <img src={telephoneIcon} alt="" srcset="" />
              </div>
              <p><span className="bold-text">Schedule a call</span>  with a Risepointe design expert to get things rolling</p>
            </div>
            <div className="analysis-box info-child">
            <div className="analysis-icons">
                <img src={analysisIcon} alt="" srcset="" />
              </div>
              <p>Enjoy an on-site <span className="bold-text">Needs Analysis™</span> with interactive planning and vision session</p>
            </div>
            <div className="custom-plan info-child">
            <div className="person-icon">
                <img src={personIcon} alt="" srcset="" />
              </div>
              <p><span className="bold-text">Execute a custom plan </span> to make your facility a GREAT tool for growth</p>
            </div>
          </div>
          <div className="cta-btn">
            <a href="https://calendly.com/risepointe/lean-facility-model-follow-up" target="_blank" rel="noreferrer">
              <Button>SCHEDULE A CALL</Button>
            </a>
          </div>
        </div>
      );
    }
  };

  const showLegacyInvite = () => {
    if (isMember) {
      return null;
    } else {
      return (
        <div className="invite-legacy-container">
          <div className="invite-legacy">
            <h1>Invite your team members</h1>
            <p>Invite your leadership team to take this assessment and begin to better align as a team around actions to maximize Kingdom impact!</p>
            <div className="cta-btn">
              <Link to={"/invite-team/" + id} target="_blank">
                <Button>Invite Your Team</Button>
              </Link>
            </div>
          </div>
        </div>
      );
    }
  };

  

  if (isLoading) {
    return (
      <div className="container results loading">
        <CircularProgress style={{ color: '#FFDD61' }} size={60} />
      </div>
    );
  }

  return (
    <>
      <div className="container results">
        <div className="details-container">

          {showTitleText()}

          <div className="progress-bar">
            <div className="si_ass_result" style={{ position: "relative" }}>
              <div className="progress-opacity-filter" style={{ width: "100%", height: windowWidth < 831 ? "11px" : "26px", bottom: windowWidth < 831 ? "-11px" : "-26px" }}>
                <div className="transparent" style={{ width: `${Math.round(score)}%`, height: windowWidth < 831 ? "11px" : "26px", background: 'linear-gradient(270deg, #FFDD62 -51.01%, #FFF4CE 100%)' , borderTopLeftRadius:'11px', borderBottomLeftRadius:'11px' }}></div>
                <div className="opaque" style={{ width: `${(100 - Math.round(score))}%`, height: windowWidth < 831 ? "11px" : "26px", backgroundColor: "rgba(255, 255, 255, 0.75)" }}></div>
              </div>
              <div className="si_ass_progress">
                <div className="progress-line" style={{ left: Math.round(score) + '%' }}>
                  {/* <div className="progress-text" style={ windowWidth < 651 && score < 30 ? { left: '-50px', width: '10ch', top: '-85px' } : getBarTextLeft(score)}>{showProgressBarText(score)}</div> */}
                </div>
                <span className="msi_score" style={getLeftMargin()}>{Math.round(score)}%</span>
                <div className="score-arrow" style={{ left: Math.round(score) + '%' }}>
                  {/* <div className="progress-text" style={ windowWidth < 651 && score < 30 ? { left: '-50px', width: '10ch', top: '-85px' } : getBarTextLeft(score)}>{showProgressBarText(score)}</div> */}
                </div>
              </div>
              <div className="pervalue">
                <h4 className="progress-0-percent" style={{ minWidth: '0%' }}></h4>
                {/* <h4 className="progress-20-percent" style={{ minWidth: '20%' }}></h4> */}
                <h4 className="progress-25-percent" style={{ minWidth: '25%' }}></h4>
                {/* <h4 className="progress-40-percent" style={{ minWidth: '20%' }}></h4> */}
                <h4 className="progress-50-percent" style={{ minWidth: '25%' }}></h4>
                {/* <h4 className="progress-60-percent" style={{ minWidth: '20%' }}></h4> */}
                <h4 className="progress-75-percent" style={{ minWidth: '25%' }}></h4>
                {/* <h4 className="progress-80-percent" style={{ minWidth: '30%' }}></h4> */}
                <h4 className="progress-100-percent" style={{ minWidth: '25%' }}></h4>
                {/* <h4 className="vexpe" style={{ minWidth: '5%' }}></h4> */}
              </div>
              
              
              <div className="si_pro_header">
              <h4 className="progress-0-percent" style={{ minWidth: '0%' }}></h4>
                <h4 className="progress-25-percent" style={{ minWidth: '25%' }}></h4>
                {/* <h4 className="progress-40-percent" style={{ minWidth: '20%' }}></h4> */}
                {/* <h4 className="progress-50-percent" style={{ minWidth: '20%' }}></h4> */}
                {/* <h4 className="progress-60-percent" style={{ minWidth: '20%' }}></h4> */}
                <h4 className="progress-50-percent" style={{ minWidth: '25%' }}></h4>
                <h4 className="progress-75-percent" style={{ minWidth: '25%' }}></h4>
                {/* <h4 className="progress-100-percent" style={{ minWidth: '25%' }}></h4> */}
                
                {/* <h4 className="eexpe1" style={{ minWidth: '30%' }}></h4> */}
                {/* <h4 className="eexpe" style={{ minWidth: '30%' }}></h4> */}
              </div>
              
             
              <div className="pervalue-text">
                <p className="not-aligned" style={{ width: windowWidth > 831 ? "25%" : "25%", fontWeight: score <= 25 ? 700 : 400 }}>Underutilized</p>
                <p className="not-aligned" style={{ width: "25%", fontWeight: score > 20 && score <= 50 ? 700 : 400 }}>Unproductive</p>
                
                <p className="some-aligned" style={{ width: "25%", fontWeight: score > 50 && score <= 75 ? 700 : 400 }}>Efficient</p>
                <p className="some-aligned" style={{ width: "25%", fontWeight: score > 75  ? 700 : 400 }}>Lean</p>
                
                {/* <p className="high-aligned" style={{ width: "20%", fontWeight: score > 80 ? 700 : 400 }}>Superb!</p> */}
              </div>

            </div>
          </div>
          {/* <div className="spacer"></div>
          <div className="spacer"></div> */}

          {showScoreText()}
          {/* <h3 className="pre-score-header">Here's how you scored on your 4 Ps:</h3> */}
          <div className="score-section-container">

            {sectionsAndSubScores?.map((sec, idx) => {
              const lowestScore = sortedSectionsAndSubScores[0]?.section;
              const areAllScoresSame = sortedSectionsAndSubScores.every(obj => obj.score === sortedSectionsAndSubScores[0]?.score);
              let customColor = "#FFDD61";
              let customTextStyles = { color: "#343333", fontWeight: 400 };
              if (sec.section === lowestScore && sec.score < 80) {
                if (!areAllScoresSame) customColor = "#EA1C22";
                customTextStyles = { color: "#EA1C22", fontWeight: 700 };
              }
              return (
              <div key={sec.section} className="score-section">
                {/* <p className="score-txt">{Math.round(sec.score)}%</p> */}
                <div className="score-slider">
                  {/* <LinearProgress className="custom-bar" variant="determinate" value={Math.round(sec?.score)} 
                  sx={{ backgroundColor: customColor,
                    '& .MuiLinearProgress-bar': { backgroundColor: `${customColor} !important` },
                  }} /> */}
                  <CircularProgressWithLabel size={136} customcolor={customColor} className="custom-bar" variant="determinate" value={Math.round(sec?.score)} thickness={3} />
                </div>
                {/* <p className="score-name" style={customTextStyles}>{sec.section}</p> */}
                <p onClick={() => setActiveScoreSection(sec.section)} style={activeScoreSection == sec.section ? { color: '#343333', fontWeight: '700' } : null} className="score-name">{sec.section}</p>
              </div>)
            })}

          </div>

          <div className="lean-facility-report-break-line">
            <div className="left-line"></div>
            <h2>Lean Facility Report</h2>
            <div className="right-line"></div>
          </div>

          <div className="lean-facility-report-container">
            <div className="seats-attendance-container">
              <div className="total-no-of-seats">
                <div className="total-no-of-seats-heading">
                  LEAN NUMBER OF SEATS
                </div>
                <div className="current-lean-count-container">
                  {/* <div className="current-count-container">
                    <div className="current-count-heading">Current Count</div>
                    <div className="current-count">{seats}</div>
                  </div>
                  <div className="middle-break-line"></div> */}
                  <p className="text-above-score">A Lean Church Facility layout with the same square footage and environments as yours would have these many seats:</p>
                  <div className="lean-count-container">
                    {/* <div className="lean-count-heading">Lean Count</div> */}
                    <div className="lean-count">{leanSeats}</div>
                  </div>
                  <p className="text-below-score">A lower number may indicate inefficiency in other areas of the facility.</p>
                  <p className="text-below-score">A higher number may indicate another lid in your facility (kids, lobby, etc).</p>
                  <p style={{ fontWeight: 600 }}>How does your facility compare?</p>
                </div>
              </div>
              {/* <div className="middle-break-seats-attendance-line"></div> */}
              <div className="weekend-attendance">
                <div className="weekend-attendance-heading">
                  LEAN WEEKEND ATTENDANCE
                </div>
                <div className="current-lean-count-container">
                  {/* <div className="current-count-container">
                    <div className="current-count-heading">Current Count</div>
                    <div className="current-count">{attendance}</div>
                  </div>
                  <div className="middle-break-line"></div> */}
                  <p className="text-above-score">If you achieve your Lean seat count, maintain your number of services, and an adult-to-kid ratio of 4:1, <span>your maximum weekend attendance should be:</span></p>
                  <div className="lean-count-container">
                    {/* <div className="lean-count-heading">Lean Count</div> */}
                    <div className="lean-count">{attendanceLean}</div>
                  </div>
                  <p className="text-below-score">If the building is a lid or barrier to your efficiency, it will be difficult or impossible to hit this number.</p>
                  <p className="text-below-score">If you have achieved more than this number, it may be time to church-plant, add services, or expand.</p>
                  <p style={{ fontWeight: 600 }}>What areas of your building could be lids to your growth?</p>
                </div>
              </div>
            </div>
          </div>
          
          {
            !isMember ? <>
            <div className="call-section">
              <p className="get-on-call-note">Get on a call to understand your report better and maximize your mission impact.</p>
              <div className="schedule-a-call-cta">
                <a href="https://calendly.com/risepointe/lean-facility-model-follow-up" target="_blank" rel="noreferrer"><Button>SCHEDULE A CALL</Button></a>
              </div>
              <div style={{height: "15px"}}></div>
            </div>
            </> : <><div style={{height: "15px"}}></div></>
          }

            {/* {getSectionBottomBar()} */}

            {/* <div style={{ height: "10px", padding: "10px" }}></div> */}

          {/* {!isMember && <div className="invite-cta-btn">
            <Link to={"/invite-team/" + id} target="_blank">
              <Button>INVITE MY TEAM</Button>
            </Link>
          </div>} */}

         <div className="charts-accordions-container">
            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<CustomExpandIcon />}><p>SITE Utilization</p></AccordionSummary>
              <AccordionDetails>
                <ColumnChart variables={variables} chartId={"699"} />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<CustomExpandIcon />}><p>SEATS PER SQUARE FOOT</p></AccordionSummary>
              <AccordionDetails>
                <CustomStackedColumnChart variables={variables} chartId={"701"} />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<CustomExpandIcon />}><p>STORAGE</p></AccordionSummary>
              <AccordionDetails>
                <TextChart variables={variables} chartId={"703"} />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<CustomExpandIcon />}><p>MINISTRY AREA RATIO</p></AccordionSummary>
              <AccordionDetails>
                <ColumnChart variables={variables} chartId={"705"} />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<CustomExpandIcon />}><p>PARKING</p></AccordionSummary>
              <AccordionDetails>
                <CustomStackedColumnChart variables={variables} chartId={"707"} />
              </AccordionDetails>
            </Accordion>
          </div>

        </div>
      </div>

      {showTeamMember()}
      
      {/* {showLegacyInvite()} */}

      {showInvite()}

      {/* {sendInvite()} */}

      {showTestimonial()}

      {/* {showResources()} */}
      

      {/* <Testimonial /> */}


      {/* <div className="testimonial-container">

        <div className="testimonial">

          <div className="testi">
            <span className="quotation-mark">“</span>Doing this assessment as a team gave us better insights into our level of alignment with our school board. It also helped us put a plan in place to make our meetings more productive, so that we continue to align on the board's objectives together.<span className="quotation-mark bottom"><div className="quotation-block">“</div></span>
          </div>

          <div className="avatar">
            <img src={testimonialImage} />
          </div>

          <div className="designation">
            Sam Davis<br />
            <span>Lead Pastor, Springs of Life Church</span>
          </div>

        </div>

      </div> */}

      {showSchedule()}

      {/* {showLegacyInvite()} */}

      {/* </div> */}

    </>

  );
};

export default Results;


function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative' }}>
      <CircularProgress variant="determinate" 
        sx={{ color: "#E9E9E9 !important", position: 'absolute', }}
        size={40} thickness={4} {...props} value={100} />
      <CircularProgress variant="indeterminate" disableShrink {...props} 
      value={-props.value} // to show anticlockwise progress 
      sx={{ 
        '&': { color: `${props.customcolor} !important`, zIndex: 1, },
        '&.MuiCircularProgress-root': {
          strokeLinecap: 'round',
        },
      }} />
      <Box sx={{ top: 0, left: 0, bottom: 0, right: 0, position: 'absolute',
          display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
        <p variant="caption" component="div" color="text.primary" style={{ fontSize: "24px", fontWeight: "700", lineHeight: "30px", margin: "0 auto" }}>
          {`${Math.round(props.value)}%`}
        </p>
      </Box>
    </Box>
  );
}

