import { useState, useEffect, useContext } from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { useParams } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import Select from 'react-select';
import { isEmail } from 'validator';

import prevArrow from '../Assets/Images/prev-arrow.svg';
import nextArrow from '../Assets/Images/next-arrow.svg';
import hourGlass from '../Assets/Images/hourglass.gif';


import '../App.scss';
import AdditionalQuestionContext from '../Store/AdditionalQuestionContext';

const jobTitleValues = [
  {value: "Other", label: "Other"},
  {value: "Administrative Pastor", label: "Administrative Pastor"},
  {value: "Lead Pastor", label: "Lead Pastor"},
  {value: "Campus Pastor", label: "Campus Pastor"},
  {value: "Children's Pastor", label: "Children's Pastor"},
  {value: "College Pastor", label: "College Pastor"},
  {value: "Community Pastor", label: "Community Pastor"},
  {value: "Connections Pastor", label: "Connections Pastor"},
  {value: "Creative Arts Pastor", label: "Creative Arts Pastor"},
  {value: "Discipleship Pastor", label: "Discipleship Pastor"},
  {value: "Executive Pastor", label: "Executive Pastor"},
  {value: "Family Pastor", label: "Family Pastor"},
  {value: "High School Pastor", label: "High School Pastor"},
  {value: "Leadership Development Pastor", label: "Leadership Development Pastor"},
  {value: "Middle School Pastor", label: "Middle School Pastor"},
  {value: "Missions Pastor", label: "Missions Pastor"},
  {value: "Outreach Pastor", label: "Outreach Pastor"},
  {value: "Personal Assistant to Lead Pastor", label: "Personal Assistant to Lead Pastor"},
  {value: "Senior Pastor", label: "Senior Pastor"},
  {value: "Small Group Pastor", label: "Small Group Pastor"},
  {value: "Stewardship Pastor", label: "Stewardship Pastor"},
  {value: "Student Pastor", label: "Student Pastor"},
  {value: "Teacher Pastor", label: "Teacher Pastor"},
  {value: "Worship Pastor", label: "Worship Pastor"},
  {value: "Administrative Assistant", label: "Administrative Assistant"},  
  {value: "AV Director", label: "AV Director"},
  {value: "Bookkeeper", label: "Bookkeeper"},
  {value: "Chief Financial Officer", label: "Chief Financial Officer"},
  {value: "Children's Ministry Coordinator", label: "Children's Ministry Coordinator"},
  {value: "Communications Coordinator", label: "Communications Coordinator"},
  {value: "Communications Director", label: "Communications Director"},
  {value: "Communications Project Manager", label: "Communications Project Manager"},
  {value: "Content Writer", label: "Content Writer"},
  {value: "Digital Media Analyst", label: "Digital Media Analyst"},
  {value: "Events Coordinator", label: "Events Coordinator"},
  {value: "Facility Manager", label: "Facility Manager"},
  {value: "Graphic Designer", label: "Graphic Designer"},
  {value: "Groups Director", label: "Groups Director"},
  {value: "Guest Services Director", label: "Guest Services Director"},
  {value: "Human Resources Coordinator", label: "Human Resources Coordinator"},
  {value: "IT Director", label: "IT Director"},
  {value: "Mission and Outreach Coordinator", label: "Mission and Outreach Coordinator"},
  {value: "Office Manager", label: "Office Manager"},
  {value: "Online Community Manager", label: "Online Community Manager"},
  {value: "Pastoral Care Minister", label: "Pastoral Care Minister"},
  {value: "Preschool Director", label: "Preschool Director"},
  {value: "Production Coordinator", label: "Production Coordinator"},
  {value: "Social Media Manager", label: "Social Media Manager"},
  {value: "Video Producer", label: "Video Producer"},
  {value: "Volunteer Coordinator", label: "Volunteer Coordinator"},
  {value: "Web Developer", label: "Web Developer"},
  {value: "Worship Leader", label: "Worship Leader"},
];

const denominationValues = [
{ value: "African Meth. Episc. Zion", label: "African Meth. Episc. Zion" },
{ value: "African Methodist Episcopal", label: "African Methodist Episcopal" },
{ value: "Assembly of God", label: "Assembly of God" },
{ value: "Baptist", label: "Baptist" },
{ value: "Baptist - Missionary" , label: "Baptist - Missionary" },
{ value: "Baptist - National", label: "Baptist - National" },
{ value: "Baptist - SBC", label: "Baptist - SBC" },
{ value: "Catholic", label: "Catholic" },
{ value: "Christian Methodist Episcopal", label: "Christian Methodist Episcopal" },
{ value: "Church of Christ", label: "Church of Christ" },
{ value: "Church of God", label: "Church of God" },
{ value: "Church of God in Christ", label: "Church of God in Christ" },
{ value: "Episcopal", label: "Episcopal" },
{ value: "Evangelical", label: "Evangelical" },
{ value: "Jewish", label: "Jewish" },
{ value: "Lutheran", label: "Lutheran" },
{ value: "Lutheran - ELCA", label: "Lutheran - ELCA" },
{ value: "Lutheran - Missouri Synod", label: "Lutheran - Missouri Synod" },
{ value: "Methodist", label: "Methodist" },
{ value: "Non - Denominational", label: "Non - Denominational" },
{ value: "Other Denomination", label: "Other Denomination" },
{ value: "Pentecostal", label: "Pentecostal" },
{ value: "Presbyterian", label: "Presbyterian" },
]

const Details = (props) => {

  const addCtx = useContext(AdditionalQuestionContext);
  let navigate = useNavigate();
  const location = useLocation();

  const { id, pageId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const [isSaving, setIsSaving]       = useState(false);
  const [isLoading, setIsLoading]     = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isMember, setIsMember]       = useState(true);

  const [userId, setUserId]     = useState(0);
  const [firstName, setFirstName]     = useState("");
  const [lastName, setLastName]       = useState("");
  // const [fullName, setFullName] = useState("");
  const [email, setEmail]             = useState("");
  const [jobTitle, setJobTitle]       = useState("");
  const [selectedValue, setSelectedValue]         = useState();
  const [jobTitleMember, setJobTitleMember]       = useState("");
  const [churchName, setChurchName]   = useState("");
  const [schoolName, setSchoolName]   = useState("");
  const [denomination, setDenomination] = useState("");
  const [zipCode, setZipCode]         = useState("");
  const [firstNameError, setFirstNameError]       = useState(false);
  const [lastNameError, setLastNameError]         = useState(false);
  // const [fullNameError, setFullNameError] = useState(false);
  const [emailError, setEmailError]               = useState(false);
  const [jobTitleError, setJobTitleError]         = useState(false);
  const [churchNameError, setChurchNameError]     = useState(false);
  const [denominationError, setDenominationError] = useState(false);
  const [schoolNameError, setSchoolNameError]     = useState(false);
  const [zipCodeError, setZipCodeError]           = useState(false);
  const [showMyResultsBtn, setShowMyResultsBtn] = useState(false);

  const [btnDisabled, setBtnDisabled] = useState(true);

  useEffect(() => {
    console.log('location',location)
    const app = document.querySelectorAll('.App');

    if(location.pathname='/start'){
      document.body.className = 'change-background'
      document.className = 'change-background'
      app.forEach((element) => {
        element.classList.add('background-filter');
      });
    }
    else{
      document.body.className = ''
    }

    return () => {
      document.body.classList.remove('change-background');
    };
  }, [location])

  useEffect(() => {
    getUserDetails();
    // if(searchParams.get("member") != null) {
    //   getUserDetails();
    // } else {
    //   setIsMember(false);
    // }
    // document.body.style.backgroundColor = "#EEEEEE";
  }, []);

  // const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  // const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z0-9-]{2,}$/;
  // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const nameRegex = /^[A-Za-zÀ-ÖØ-öø-ÿ\s'-]+$/; // allows letters, diacritics, spaces, single quotes, hyphens
  
  useEffect(() => {
    
    const updateFormCtxDetails = (fieldName, value) => {
      if (value !== addCtx.formDetails?.[fieldName]) {
        addCtx.addFormDetails(prev => ({ ...prev, [fieldName]: value }));
      }
    };

    // updateFormCtxDetails("fullName", fullName);
    updateFormCtxDetails("firstName", firstName);
    updateFormCtxDetails("lastName", lastName);
    updateFormCtxDetails("email", email);
    updateFormCtxDetails("jobTitle", jobTitle);
    updateFormCtxDetails("churchName", churchName);
    // updateFormCtxDetails("denomination", denomination);
    updateFormCtxDetails("zipCode", zipCode);

  }, [firstName, lastName, email, jobTitle, churchName, zipCode]);

  useEffect(() => {
    if (isMember) {
      if (firstName?.length && lastName?.length && email?.length && jobTitle?.length) {
        setBtnDisabled(false);
      } else setBtnDisabled(true);
    } else if (!isMember) {
      if (firstName?.length && lastName?.length && churchName?.length && email?.length && jobTitle?.length && zipCode.length) {
        setBtnDisabled(false);
      } else setBtnDisabled(true);
    }
  }, [firstName, lastName, churchName, email, jobTitle, zipCode, isMember]);

  useEffect(() => {
    let formDetails = addCtx.formDetails;

    if (formDetails?.firstName?.length)  setFirstName(formDetails.firstName);
    if (formDetails?.lastName?.length)   setLastName(formDetails.lastName);
    // if (formDetails?.fullName?.length)  setFullName(formDetails.fullName);
    if (formDetails?.email?.length)      setEmail(formDetails.email);
    if (formDetails?.churchName?.length) setChurchName(formDetails.churchName);
    // if (formDetails?.denomination?.length) setDenomination(formDetails.denomination);
    if (formDetails?.zipCode?.length)    setZipCode(formDetails.zipCode);
    // if (formDetails?.jobTitle?.length) setJobTitle(formDetails.jobTitle);

    if (formDetails?.jobTitle?.length) {
      let selected = jobTitleValues?.find(job => job.value === formDetails?.jobTitle);
      // console.log("typeof selected", typeof selected, selected);
      setSelectedValue(selected);
      selected?.value && setJobTitle(selected?.value);
    }
    // if (formDetails?.denomination?.length) {
    //   let selected = denominationValues?.find(denom => denom.value === formDetails?.denomination);
    //   // console.log("typeof selected", typeof selected, selected);
    //   setSelectedValue(selected);
    //   selected?.value && setDenomination(selected?.value);
    // }
  }, [addCtx.formDetails]);

  console.log("addCtx.formDetails", addCtx.formDetails);
  // console.log("typeof jobTitle", typeof jobTitle, jobTitle);

  const getUserDetails = () => {
    setIsLoading(true);
  
    // fetch(process.env.REACT_APP_API_URI + '/v1/user/' + searchParams.get("member"), {    
    fetch(process.env.REACT_APP_API_URI + '/v3/user/assessment/' + id + '/details', {    
      method: 'GET',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' }
    }).then(response => {
      return response.json();
    }).then(data => {
      console.log(data);
      if(data.status == "success") {
        if(data.isMember == true) {
          setIsMember(true);
          setUserId(data.userId);
          setFirstName(data.firstName);
          setLastName(data.lastName);
          // setFullName(data.fullName);
          setEmail(data.email);
          // setJobTitle("none");
          // setJobTitle("");
        } else {
          setIsMember(false);
        }
        
        setIsLoading(false);
      } else {
        // setOpenSnackbar(true);
      }
    });
  }

  const updateFirstName = (event) => {
    setFirstName(event.target.value);
    checkFormValid();
  }

  const updateLastName = (event) => {
    setLastName(event.target.value);
    checkFormValid();
  }

  // const updateFullName = (event) => {
  //   setFullName(event.target.value);
  //   checkFormValid();
  // }

  const updateEmail = (event) => {
    setEmail(event.target.value);
    checkFormValid();
  }

  // const updateJobTitle = (event) => {
  const updateJobTitle = (event) => {
    setJobTitle(event.target.value);
    // setSelectedValue(selectedOption);
    // setJobTitle(selectedOption.value);
    checkFormValid();
  }

  useEffect(()=>{
    console.log('selectedValue',selectedValue)
    console.log('jobTitle',jobTitle)
  },[selectedValue,jobTitle])
  
  // const updateSchoolName = (event) => {
  //   setSchoolName(event.target.value);
  //   checkFormValid();
  // }
  
  const updateChurchName = (event) => {
    setChurchName(event.target.value);
    checkFormValid();
  }
  
  // const updateDenomination = (event) => {
  const updateDenomination = (selectedOption) => {
    // setDenomination(event.value);
    // console.log("DENOMINATION", event.value);
    setSelectedValue(selectedOption);
    setDenomination(selectedOption.value);
    checkFormValid();
  }

  const updateZipCode = (event) => {
    setZipCode(event.target.value);
    checkFormValid();
  }

  const checkFormValid = () => {
    if (isMember) {
      // console.log(firstName); console.log(jobTitle); console.log(jobTitleMember);
      if (firstName && lastName && email && jobTitle) {
        console.log("We get here");
        setIsFormValid(true);
        setShowMyResultsBtn(true);
      } else {
        setIsFormValid(false);
        setShowMyResultsBtn(false);
      }
    } else {
      if (firstName && lastName && nameRegex.test(firstName) && nameRegex.test(lastName) && email && isEmail(email) && churchName && zipCode.length > 3 && zipCode.length < 13 && jobTitle) {
      // if(fullName && email && isEmail(email) && churchName && zipCode.length > 3 && zipCode.length < 13) {
        setIsFormValid(true);
        setShowMyResultsBtn(true);
      } else {
        setIsFormValid(false);
        setShowMyResultsBtn(false);
      }
    }
  }

  const showErrors = () => {
    if(!firstName || !nameRegex.test(firstName)) {
      setFirstNameError(true);
      setTimeout(() => { console.log(firstNameError); }, 2000);
    }
    if(!lastName || !nameRegex.test(lastName)) {
      setLastNameError(true);
    }
    // if(!fullName || !nameRegex.test(fullName)) {
    //   setFullNameError(true);
    //   setTimeout(() => { console.log(fullNameError); }, 2000);
    // }

    if(!isEmail(email)) {
      setEmailError(true);
    }
    if(!jobTitle) {
      setJobTitleError(true);
    }
    // if(schoolName == "") {
    //   setSchoolNameError(true);
    // }
    if(!churchName) {
      setChurchNameError(true);
    }
    if(!denomination) {
      setDenominationError(true);
    }
    if(zipCode.length < 4) {
      setZipCodeError(true);
    }
  }

  const submitToApi = () => {
    
    let apiCall         = '/v1/assessment/' + id + '/leader';
    if(isMember) {
      apiCall           = '/v1/assessment/' + id + '/member/' + userId;
    }

    let jsonBody = JSON.stringify({
      details: {
        "firstName": firstName?.toString()?.trim(),
        "lastName": lastName?.toString()?.trim(),
        "email": email?.toString()?.trim(),
        "jobTitle": jobTitle?.toString()?.trim() || "",
        "churchName": churchName?.toString()?.trim(),
        // "denomination": denomination?.toString()?.trim(),
        "zipCode": zipCode?.toString()?.trim(),
      },
      formVersion: 2
    });

    fetch(process.env.REACT_APP_API_URI + apiCall, {
      method: 'PUT',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: jsonBody
    }).then(response => {
      return response.json();
    }).then(data => {
      if(data.status === "success") {
        localStorage.removeItem("assessmentAnswers");
        localStorage.removeItem("additionalAnswers");
        navigate("/pre-assessment/" + id + "/709", { replace: true });
      } else {
        setIsSaving(false);
      }
    }).catch(err => {
      setIsSaving(false);
      console.error("Failed to submit user details...", err);
    })

  }

  const handleSubmit = () => {
    setIsSaving(true);
    console.log("Runnign");

    if (isMember && firstName && lastName && email && jobTitle) {
      submitToApi();
    } else if (!isMember && firstName && lastName && nameRegex.test(firstName) && nameRegex.test(lastName) && isEmail(email) && jobTitle && churchName && zipCode.length > 3 && zipCode.length < 13) {
      submitToApi();
    } else {
      showErrors();
      setIsSaving(false);
    }

  }

  const showButton = () => {
    if (isSaving) {
      return <div className="button-progress"><CircularProgress style={{ color: '#000000' }} size={23} /></div>
    }
    return "CONTINUE";
  }

  const showHintText = (field) => {
    if (field == "firstName") {
      if (firstNameError) {
        if (!nameRegex.test(firstName)) {
          return <div className="error-text">Invalid first name.</div>
        } else return <div className="error-text">Please confirm your first name.</div>
      }
    }
    if (field == "lastName") {
      if (lastNameError) {
        if (!nameRegex.test(lastName)) {
          return <div className="error-text">Invalid last name.</div>
        } else return <div className="error-text">Please confirm your last name.</div>
      }
    }

    // if (field == "fullName") {
    //   if (fullNameError) {
    //     if (!nameRegex.test(fullName)) {
    //       return <div className="error-text">Invalid name.</div>
    //     } else return <div className="error-text">Please confirm your full name.</div>
    //   }
    // }

    if (field == "email") {
      if(emailError) {
        return <div className="error-text">Please enter a valid email address.</div>
      }
    }
    if (field == "jobTitle") {
      if (jobTitleError) {
        return <div className="error-text">Please provide your job title.</div>
      }
    }
    if (field == "orgName") {
      if (churchNameError) {
        return <div className="error-text">Please confirm your organization name.</div>
      }
    }
    // if(field == "churchName") {
    //   if(churchNameError) {
    //     return <div className="error-text">Please confirm your church name.</div>
    //   }
    // }
    // if(field == "denomination") {
    //   if(denominationError) {
    //     return <div className="error-text">Please confirm your denomination.</div>
    //   }
    // }
    if (field == "zipCode") {
      if (zipCodeError) {
        return <div className="error-text">Please confirm your ZIP code.</div>
      }
    }
  }

  const removeHintText = (field, e) => {

    // change active input field title color 
    // if(e.target.closest(".form-detail")) {
    //   document.body.querySelectorAll(".input-title").forEach(elm => elm.style.color = "#4A4A4A")
    //   e.target.closest(".form-detail").querySelector(".input-title").style.color = "#ED2028";
    // }

    if (e.target.closest(".form-detail")) {
      // document.body.querySelectorAll(".form-details-text").forEach(elm => elm.style.backgroundColor = "#F3F3F3")
      e.target.closest(".form-detail").querySelector(".form-details-text").style.backgroundColor = "#F4F4F4";
      e.target.closest(".form-detail").querySelector(".form-details-text").style.border = "1px solid #343333";
    }


    if (field == "firstName") {
      setFirstNameError(false);
    }
    if (field == "lastName") {
      setLastNameError(false);
    }

    // if(field == "fullName") {
    //   setFullNameError(false);
    // }

    if (field == "email") {
      setEmailError(false);
    }
    if (field == "jobTitle") {
      setJobTitleError(false);
    }
    if (field == "orgName") {
      setChurchNameError(false);
    }
    // if(field == "denomination") {
    //   setDenominationError(false);
    // }
    // if(field == "churchName") {
    //   setChurchNameError(false);
    // }
    if (field == "zipCode") {
      setZipCodeError(false);
    }
  }

  const prevQuestion = () => {
    // if(searchParams.get("leader")) {
    //   navigate('/assessment/' + id + '/review?' + searchParams, { replace: true });
    // } else {
    //   navigate('/assessment-additional/' + id + "?" + searchParams, { replace: true });
    // }
    localStorage.setItem("backFromDetailsPage", true);
    // navigate("/assessment/" + id + "/650?" + searchParams, { replace: true });
    // navigate("/assessment-additional/" + id + "/687?" + searchParams, { replace: true });
    navigate("/charts/" + id + "/707", { replace: true });
  };

  if (isLoading) {
		return <div className="container details loading"><CircularProgress style={{ color: '#FFDD61' }} size={60} /></div>
	}


  const bgColor = selectedValue?.length ? "#FFFFFF" : "#F3F3F3";
  
  return (
    <div className="container details">
      
      <div className="details-container">
        
        
        <h1>
          {/* <img src={hourGlass} width={38} height={38} style={{margin:"-6px 0"}}/> */}
           You've completed the first part of the assessment—<br/>almost there!
        </h1>
        {/* <p>"<span style={{ color: "#c02b0a" }}>*</span>" indicates required fields</p> */}
        <div style={{ height: '10px' }}></div>
        {/* <p>As we tabulate your report, please confirm your details so that we can email you a copy.</p> */}
        <p>Confirm your details so we can email you a report of your results after you complete this test.</p>

        <div className="form">

          <div className="form-detail">
            <p className="input-title">First Name<span className="required-field">*</span></p>
            <TextField hiddenLabel placeholder="E.g. Simon" value={firstName} onFocus={(e) => removeHintText("firstName", e)} onChange={(e) => {updateFirstName(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text"/>
            {showHintText("firstName")}
          </div>

          <div className="form-detail">
            <p className="input-title">Last Name<span className="required-field">*</span></p>
            <TextField hiddenLabel placeholder="E.g. Peter" value={lastName} onFocus={(e) => removeHintText("lastName", e)}  onChange={(e) => {updateLastName(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
            {showHintText("lastName")}
          </div>
          {/* 
          <div className="form-detail">
            <p className="input-title">Full name<span className="required-field">*</span></p>
            <TextField hiddenLabel placeholder="Simon Peter" value={fullName} onFocus={(e) => removeHintText("fullName", e)}  onChange={(e) => {updateFullName(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
            {showHintText("fullName")}
          </div> */}

          <div className="form-detail">
            <p className="input-title">Email<span className="required-field">*</span></p>
            <TextField hiddenLabel placeholder="E.g. speter@rock.church" disabled={isMember} value={email} onFocus={(e) => removeHintText("email", e)} onChange={(e) => {updateEmail(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
            {showHintText("email")}
          </div>

          <div className="form-detail job-details-section">
            <p className="input-title">Job Title<span className="required-field">*</span></p>
            <TextField hiddenLabel placeholder="E.g. Senior Pastor" value={jobTitle} onFocus={(e) => removeHintText("jobTitle", e)} onChange={(e) => {updateJobTitle(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
            {showHintText("jobTitle")}
            
             {/* <TextField select hiddenLabel placeholder="Select/start typing" value={jobTitle} onFocus={(e) => removeHintText("jobTitle", e)} onChange={(e) => {updateJobTitle(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text">
               <MenuItem key={0} value="Select/start typing" disabled>Select/start typing</MenuItem>
              {jobTitleValues.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.value}
                </MenuItem>
              ))}
            </TextField>  */}

            {/* Added react-select instead of MUI TextField */}
             {/* <Select classNamePrefix="react-select" className= "form-details-text react-select" 
              options={jobTitleValues} 
              onFocus={(e) => removeHintText("jobTitle", e)}
              onChange={updateJobTitle}
              value={selectedValue}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: '#3B3A3A', // selected option color
                  // primary25: '#4298B544',
                  primary25: '#EA1C2244', // option hover highlight color
                  primary50: '#EA1C2244', // option onclick highlight color
                  neutral0: '#F4F4F4',
                  neutral50: '#B5B5B5', // placeholder text color
                },
                cursor: "pointer",
              })}
              placeholder={"E.g. Senior Pastor"} />
            {showHintText("jobTitle")} */}
          </div>  

          {/* {
            !isMember ? (
              <div className="form-detail">
                <p className="input-title">School name<span className="required-field">*</span></p>
                <TextField hiddenLabel placeholder="Rock Church" value={schoolName} onFocus={(e) => removeHintText("schoolName", e)} onChange={(e) => {updateSchoolName(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
                {showHintText("churchName")}
              </div>
            ) : null
          } */}
          
          {
            !isMember ? (
              <div className="form-detail">
                <p className="input-title">Church/Organization Name<span className="required-field">*</span></p>
                <TextField hiddenLabel placeholder="E.g. Rock Church" value={churchName} onFocus={(e) => removeHintText("orgName", e)} onChange={(e) => { updateChurchName(e) }} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
                {showHintText("orgName")}
              </div>
            ) : null
          }

          {
            !isMember ? (
              <div className="form-detail">
                <p className="input-title">ZIP Code<span className="required-field">*</span></p>
                <TextField hiddenLabel placeholder="E.g. 11618" value={zipCode} onFocus={(e) => removeHintText("zipCode", e)} onChange={(e) => { updateZipCode(e) }} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
                {showHintText("zipCode")}
              </div>
            ) : null
          } 

          
           {
            // !isMember ? (
            //   <div className="form-detail">
            //     <p className="input-title">Denomination<span className="required-field">*</span></p>
            //     {/* <TextField hiddenLabel placeholder="E.g. Protestant" value={denomination} onFocus={(e) => removeHintText("denomination", e)} onChange={(e) => {updateDenomination(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" /> */}
            //     <Select classNamePrefix="react-select" className="form-details-text react-select" 
            //       options={denominationValues} 
            //       onFocus={(e) => removeHintText("denomination", e)}
            //       onChange={(e) => updateDenomination(e)}
            //       value={selectedValue}
            //       theme={(theme) => ({
            //         ...theme,
            //         colors: {
            //           ...theme.colors,
            //           primary25: '#FFFFFF',
            //           primary: '#3B3A3A',
            //           neutral0: '#F4F4F4',
            //           neutral50: '#868686', // placeholder text color
            //         },
            //       })}
            //       placeholder={"Start typing or select"} />
            //     {showHintText("Start typing or select")}
            //   </div>
            // ) : null
          }
          
          {/* {
            !isMember ? (
              <div className="form-detail">
                <p className="input-title">Denomination<span className="required-field">*</span></p>
                <TextField hiddenLabel 
                  type="text" 
                  placeholder="E.g. Protestant" 
                  className="required-field" 
                  value={denomination} 
                  onFocus={(e) => removeHintText("denomination", e)} 
                  onChange={(e) => {updateDenomination(e)}} 
                  fullWidth variant="standard" size="normal" 
                  InputProps={{ disableUnderline: true }} 
                  onInput = {(e) => {
                    return e.target.value = e.target.value?.toString().slice(0,12)
                  }}
                />
                {showHintText("zipCode")}
              </div>
            ) : null
          } */}


        </div>
          <div className="details-buttons">
            <div className="back-btn">
              <Button onClick={prevQuestion}>
                <img src={prevArrow} alt="previous button" />&nbsp;&nbsp;PREV
              </Button>
            </div>

            <div className={showMyResultsBtn ? "cta-btn-active": "cta-btn" }>
              <Button onClick={handleSubmit} disabled={btnDisabled}>
                {showButton()}
              </Button>
            </div>
          </div>

      </div>
      
    </div>
  );

}

export default Details;

