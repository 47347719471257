import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import useWindowDimensions from '../Hooks/useWindowDimensions';

import prevArrow from '../Assets/Images/prev-arrow.svg';
import nextArrow from '../Assets/Images/next-arrow.svg';
import leftArrowImg from '../Assets/Images/left-arrow.png';
import rightArrowImg from '../Assets/Images/right-arrow.png';
import leftArrowMobileImg from '../Assets/Images/left-arrow-mobile.png';
import rightArrowMobileImg from '../Assets/Images/right-arrow-mobile.png';

import CircularProgress from '@mui/material/CircularProgress';
import { Button } from '@mui/material';
// import * as d3 from 'd3';


const CustomColumnCharts = () => {

  const { windowWidth } = useWindowDimensions();  
  const { id, pageId } = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [variables, setVariables] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    
    setIsLoading(true);
		
    fetch(process.env.REACT_APP_API_URI + '/v3/user/assessment/' + id + '/pages/' + pageId, {
      method: 'GET',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' }
    }).then(response => {
      return response.json();
    }).then(data => {
      if(data.status == "success") {
        console.log(data);
        setVariables(data.data.variables);
        setIsLoading(false);
        return;
      } else {
        console.log("SOMETHING WENT WRONG");
        return;
      }
    }).catch((err) => console.error("Error while fetching form questions...", err));

    
  }, [id, pageId]);

  const renderCharts = () => {
    switch (pageId) {
      case "699": return <ColumnChart key={699} variables={variables} />
      case "701": return <CustomStackedColumnChart key={701} variables={variables} />
      case "703": return <TextPage variables={variables} />
      case "705": return <ColumnChart key={705} variables={variables} />
      case "707": return <CustomStackedColumnChart key={707} variables={variables} />
      default: return null;
    }
  }

  const prevPage = () => {
    if (pageId && (pageId >= 699 && pageId <= 707)) {
      navigate("/questions/" + id + "/" + (parseInt(pageId) - 1) + "?" + searchParams);
    }
  }
  
  const nextPage = () => {
    if (pageId == 707) {
      navigate("/details/" + id + "/708?" + searchParams);
    } else {
      navigate("/questions/" + id + "/" + (parseInt(pageId) + 1) + "/?" + searchParams);
    }
  }

  if (isLoading) {
		return <div className="custom-column-charts-container loading"><CircularProgress style={{ color: '#FFDD61' }} size={60} /></div>
	}

  return (
    <div className="custom-column-charts-container">

      {renderCharts()}

      {
        windowWidth > 830 ? <div className="step-buttons">
          <Button className="cta-prev" onClick={() => prevPage()}><img src={prevArrow} alt="Previous Page Arrow" />&nbsp;&nbsp;PREV</Button>
          <Button className="cta" onClick={() => nextPage()}><span className="next-btn">NEXT</span></Button>
        </div> : <div className="step-buttons">
          <Button className="cta-prev" onClick={() => prevPage()}><img src={prevArrow} alt="Previous Page Arrow" />&nbsp;&nbsp;</Button>
          <Button className="cta" onClick={() => nextPage()}><span className="next-btn">NEXT</span></Button>
        </div>
      }

    </div>
  )
}

const ColumnChart = (props) => {

  const { windowWidth } = useWindowDimensions();

  const { pageId } = useParams();
  let score = 75;
  let maxScore = 100;
  let chartHeader;
  let chartIdealTxt;
  let difference = 14;

  if (pageId === "699") {
    
    let siteutilScore = props.variables.find(obj => obj.variable == "siteutil");
    if(siteutilScore) {
      score = siteutilScore.score;
      if(score > 100) {
        maxScore = score;
      }
    }
    chartHeader = `Your site utilization score is <span style="border: 4px solid #FFDD61; border-radius: 50%; padding: 4px 6px;">${Math.round(score)}%</span>`;
    chartIdealTxt = `A Lean Church Facility can achieve 100 seats per acre. <br/><br/>A low score here may indicate room for future growth!`;
    if (Math.round(score) == 100) {
      chartIdealTxt = `A Lean Church Facility can achieve 100 seats per acre.`;
    } else if (Math.round(score) > 100) {
      chartIdealTxt = `A Lean Church Facility can achieve 100 seats per acre. <br/><br/>A score above 100 may indicate you operate in an urban setting or provide off-site parking.`;
    }
    difference = 85 - score;
  } else if (pageId === "705") {
    let usageScore = props.variables.find(obj => obj.variable == "usage");
    if(usageScore) {
      score = usageScore.score;
      if(score > 100) {
        maxScore = score;
      }
    }
    difference = 80 - score;
    chartHeader = `<span style="border: 4px solid #FFDD61; border-radius: 50%; padding: 4px 6px;">${Math.round(score)}%</span> of your building is ministry programmable`;
    if (Math.round(score) >= 80) {
      chartIdealTxt = `Your building is used a lot! <br/>Grab a Red Bull.`;
    } else {
      chartIdealTxt = `A Lean Church needs to specifically design its spaces for adaptability and community use.`;
    }
  }
  

  const options = {
    chart: {
      type: 'column',
      className: "highcharts-chart",
      width: windowWidth <= 650 ? 250 : 275,
    },
    tooltip: { enabled: false },
    title: {
      // text: `Your site utilization score is ${score2}%`,
      text: null,
    },
    xAxis: {
      width: windowWidth <= 650 ? "80%" : "100%",
      categories: pageId === "705" ? ["Storage"] : ["Site Utilization"],
      title: {
        text: null, // Remove X-axis title
      },
    },
    yAxis: {
      width: windowWidth <= 650 ? "80%" : "100%",
      tickPositions: pageId == 699 ? [0, 85, 100, Math.round(maxScore)] : [0, 80, 100, Math.round(maxScore)],
      gridLineWidth: 0,
      min: 0,
      max: Math.round(maxScore),
      // height: 100,
      lineWidth: 1,
      labels: {
        formatter: function () {
          // Custom label text for the specified tick positions
          if (this.value === 0) return '0%';
          else if (this.value === 80) return '80%';
          else if (this.value === 85) return '85%';
          else if (this.value === 100) return '100%';
          else return ''; // Hide labels for other positions
        },
      },
      plotBands: [{
        from: pageId == 699 ? 85 : 80,
        to: 100,
        color: '#FFDD6123',
        label: {
          // text: 'Last quarter year\'s value range'
        }
      }],
      categories: ['Non Utilization'],
      title: {
        text: null, // Remove X-axis title
      },

    },
    plotOptions: {
      column: {
        borderRadius: 0,
        dataLabels: {
          enabled: true,
          formatter: function () {
            // display the data value as a percentage inside the column
            return Math.round(score) + '%';
          },
        },
      },
    },
    series: [
      {
        // name: 'X Axis Bar',
        data: [Math.round(score)], // 100% for X Axis Bar
        color: '#FFDD61',
        pointWidth: 50, // Width of X Axis Bar
        pointPadding: 0.5, // Center it on the X-axis
        showInLegend: false,
        states: { hover: { enabled: false } }, // disable hover effect and color change
      },
    ],
    credits: { enabled: false },
  };

  function brOrSpace() {
    if (windowWidth < 831) return " ";
    else return <br/>;
  }

  return (
    <div className="chart-container chart-one">
      <h1 className="chart-header" dangerouslySetInnerHTML={{ __html: chartHeader }}></h1>
      <div className="chart-subcontainer">
        <div></div>
        <HighchartsReact highcharts={Highcharts} options={options} />
        <div><p className="chart-sidetext" style={{ textAlign: "center", maxWidth: pageId == 699 ? "22ch" : "25ch" }} dangerouslySetInnerHTML={{ __html: chartIdealTxt }}></p></div>
      </div>
      {
        pageId === "705" ? 
        (Math.round(score) == 80 ? null : <p className="chart-subheader"><span>The Lean Church Facility Model recommends</span>{brOrSpace()}studying how you might {difference < 0 ? `decrease` : `increase`} your M.A.R. by {`${Math.round(Math.abs(difference))}%`}.</p>)
        : 
        (Math.round(score) == 100 ? null : <p className="chart-subheader"><span>The Lean Church Facility Model recommends</span>{brOrSpace()}a {difference < 0 ? `${-Math.round(difference)}% decrease` : `${Math.round(difference)}% increase`} in your church site utilization.</p>)
      }
    </div>
  );
};


const CustomStackedColumnChart = (props) => {

  console.log(props);

  const { pageId } = useParams();
  const { windowWidth } = useWindowDimensions();
  const [rightArrow, setRightArrow] = useState(rightArrowImg);
  const [leftArrow, setLeftArrow]   = useState(leftArrowImg);
  const [rightText, setRightText]   = useState("");
  const [leftText, setLeftText]     = useState("Your church");


  useEffect(() => {
    if (windowWidth < 831) {
      setRightArrow(rightArrowMobileImg);
      setLeftArrow(leftArrowMobileImg);
      setRightText("A Lean Church");
      setLeftText("Your church");
    } else {
      setRightArrow(rightArrowImg);
      setLeftArrow(leftArrowImg);
      if (pageId === "701") {
        let persqft = 45;
        let persqftScore = props.variables.find(obj => obj.variable == "persqft");
        if(persqftScore) {
          persqft = persqftScore.score;
        }
        // console.log("persqft", persqft);
        setRightText(`A Lean Church needs around ${persqft} square feet per seat.`);
      } else if (pageId === "707") {
        setRightText("A Lean Church needs 2 seats per parking spot.");
      }
    }
  }, [windowWidth, pageId, props.variables]);

  let sqftperseat = 45;
  let persqft = 45;
  let seatslean = 100;
  let seats = 75;

  let score = 75;
  let total = 100;

  let difference = total - score;
  let scorePercent = (score / total) * 100;
  // score bar is 200px height & score is in % so multiple by 2 to get px height for scoreBar
  let scoreBar = scorePercent * 2;
  let differencePercent = (difference / total) * 100;
  // difference bar is 200px height & score is in % so multiple by 2 to get px height for differenceBar
  let differenceBar = differencePercent * 2;
  
  let showParkingScoreDisclaimer = false;
  let showSeatingScoreNote = false;

  let chartHeader;
  let chartSubHeader;
  let chartBottomText;
  let scoreGreaterThanTotal = false;
  let scoreEqualToTotal = false;
  // score based text changes
  if (pageId === "701") {

    let sqftperseatScore = props.variables.find(obj => obj.variable == "sqftperseat");
    if(sqftperseatScore) {
      sqftperseat = sqftperseatScore.score;
    }

    let seatsleanScore = props.variables.find(obj => obj.variable == "seatslean");
    if(seatsleanScore) {
      seatslean = seatsleanScore.score;
    }

    let seatsScore = props.variables.find(obj => obj.variable == "seats");
    if(seatsScore) {
      seats = seatsScore.score;
    }

    let persqftScore = props.variables.find(obj => obj.variable == "persqft");
    if(persqftScore) {
      persqft = persqftScore.score;
    }
    console.log(persqft);

    score = seats;
    total = seatslean;
    // if score > total then swap values for chart correction
    // otherwise percentages will be wrong since it is score / total * 100 
    if (seats > seatslean) {
      score = seatslean;
      total = seats;
      scoreGreaterThanTotal = true;
    } else {
      scoreGreaterThanTotal = false;
    }
    scoreEqualToTotal = total == score;
    difference = total - score;
    scorePercent = (score / total) * 100;
    // score bar is 200px height & score is in % so multiple by 2 to get px height for scoreBar
    differencePercent = (difference / total) * 100;
    // difference bar is 200px height & score is in % so multiple by 2 to get px height for differenceBar
    scoreBar = scorePercent * 2;
    differenceBar = differencePercent * 2;
    
    console.log("score", score); console.log("total", total);
    console.log("difference", difference); console.log("scorePercent", scorePercent);
    console.log("differencePercent", differencePercent);

    chartBottomText = "Number of Seats";
    chartHeader = `Your church uses <span style="border: 4px solid #FFDD61; border-radius: 50%; padding: 4px 6px;">${Math.round(sqftperseat)}</span> sq ft per seat`;
    if (difference > 0 && !scoreGreaterThanTotal) {
      chartSubHeader = `<span>As per the Lean Facility Model,</span><br/>a more efficient building could help accommodate ${Math.round(difference)} more seats.`;
    } else if (scoreGreaterThanTotal && difference > 0) {
      showSeatingScoreNote = true;
      chartSubHeader = `<span>In a Lean Facility Model,</span><br/>you would have ${Math.round(difference)} fewer seats.`;
    } else {
      chartSubHeader = "";
    }
    if (windowWidth < 831) chartSubHeader = chartSubHeader.replaceAll("<br/>", " ");
  } else if (pageId === "707") {
    
    let spot;
    let leanspot;

    let spotScore = props.variables.find(obj => obj.variable == "spot");
    if(spotScore) {
      spot = spotScore.score;
    }

    let leanspotScore = props.variables.find(obj => obj.variable == "leanspot");
    if(leanspotScore) {
      leanspot = leanspotScore.score;
    }

    score = spot;
    total = leanspot;
    // if score > total then swap values for chart correction
    // otherwise percentages will be wrong since it is score / total * 100 
    if (spot > leanspot) {
      score = leanspot;
      total = spot;
      scoreGreaterThanTotal = true;
    } else {
      scoreGreaterThanTotal = false;
    }
    scoreEqualToTotal = total == score;
    difference = total - score;
    scorePercent = (score / total) * 100;
    // score bar is 200px height & score is in % so multiple by 2 to get px height for scoreBar
    scoreBar = scorePercent * 2;
    differencePercent = (difference / total) * 100;
    // difference bar is 200px height & score is in % so multiple by 2 to get px height for differenceBar
    differenceBar = differencePercent * 2;

    console.log("score", score); console.log("total", total);
    console.log("difference", difference); console.log("scorePercent", scorePercent);
    console.log("differencePercent", differencePercent);

    chartBottomText = "Number of Parking Spots";
    if (difference > 0 && !scoreGreaterThanTotal) {
      showParkingScoreDisclaimer = true;
      chartHeader = "You do not have enough parking spots.";
      chartSubHeader = `You need to add ${Math.round(difference)} more parking spots<br/><span style="font-weight: 400;">in order to maximize your seat count.*</span>`;
    } else if (scoreGreaterThanTotal && difference > 0) {
      chartHeader = "You have more parking than you need.";      
      chartSubHeader = `<span style="font-weight: 400;">You have more parking than you need and </span><br/>can add ${Math.round(difference)} more seats before it becomes a lid to growth.`;
    } else {
      chartHeader = "You have the perfect amount of parking.";
      chartSubHeader = ``;
    }
    if (windowWidth < 831) chartSubHeader = chartSubHeader.replaceAll("<br/>", " ");
  }

  return (
    <div style={{ margin: "15px auto" }}>

      {/* Total Score Bar with Right Text */}
      <div className="chart-container custom-chart-container" style={{ padding: "30px 15px 5px" }}>
        <h2 dangerouslySetInnerHTML={{ __html: chartHeader }}></h2>
        <div className="flex-container" style={{ height: "200px", flexDirection: scoreGreaterThanTotal ? "column-reverse" : "column" }}>
          <div className="score-bar" style={{ height: `${scoreGreaterThanTotal ? scoreBar : differenceBar}px`, backgroundColor: scoreEqualToTotal ? "#FFDD61" : scoreGreaterThanTotal ? "#464646" : "#FFDD61", position: "relative", borderTop: scoreGreaterThanTotal ? (pageId == 707 ? "2px solid #FFDD61" : "2px dashed #FFDD61") : "none" }}>
            <div className="right-side-info">
              <div className="score-arrow">
                <span>{scoreGreaterThanTotal ? Math.round(score) : Math.round(total)}</span>&nbsp;&nbsp;<img src={rightArrow} alt="right arrow" height="12px" width="auto" />
              </div>
              <div className="score-text">
                <span className="chart-side-text light-weight">{rightText}</span></div>
            </div>
          </div>
          
          {/* Score Bar with Left Text */}
          <div className="score-bar" style={{ height: `${scoreGreaterThanTotal ? differenceBar : scoreBar}px`, backgroundColor: scoreEqualToTotal ? "#FFDD61" : "#464646", position: "relative" }}>
            <div className="left-side-info">
              <div className="score-arrow">
                <img src={leftArrow} alt="left arrow" height="12px" width="auto" /> <span>{scoreGreaterThanTotal ? Math.round(total) : Math.round(score)}</span>
              </div>
              <div className="score-text"><span className="chart-side-text" dangerouslySetInnerHTML={{ __html: leftText }}></span></div>
              {showSeatingScoreNote && 
                <div className="score-note">
                  <strong>Note:</strong> This may indicate you don't have all the same spaces as a typical church, i.e., offices, or you do ministry in Southern California and have an outdoor lobby.
                </div>}
            </div>
          </div>
        </div>
        <p className="chart-bottom-text">{chartBottomText}</p>
        {chartSubHeader == "" ? null : <p className="chart-subheader" style={{ fontWeight:  pageId == 707 ? 700 : 400 }} dangerouslySetInnerHTML={{__html: chartSubHeader}}></p>}
        {showParkingScoreDisclaimer ? <p style={{ fontSize: "18px", lineHeight: "25px", fontWeight: 300, margin: "2px auto", textAlign: "center" }}>*Urban churches with public transit can violate this rule.</p> : null}
      </div>


    </div>
  );

}


const TextPage = (props) => {

  const { windowWidth } = useWindowDimensions();
  let pageHeader = "";
  let mainText = "";
  let pageSubHeader = "";

  let score = 0;
  let storageScore = props.variables.find(obj => obj.variable == "store");
  if (storageScore) {
    score = storageScore.score;
  }

  if (score == 7) {
    pageHeader = "You have unlocked a superpower — please write a book so other churches can learn from your expertise.";
    mainText = "A Lean church has enough storage space in key locations that rooms and spaces function effectively.";
  } else if (score == 8) {
    pageHeader = "You may not have enough storage or you may be storing the wrong stuff.";
    mainText = "The Lean Facility Indicator recommends an audit of your stored items for how frequently they are used.";
  } else if (score == 9) {
    pageHeader = "You may not have enough storage or you may be storing the wrong stuff.";
    mainText = "The Lean Facility Indicator recommends an audit of your stored items for how frequently they are used.";
  } else if (score == 10) {
    pageHeader = "Get the junk out!";
    mainText = "The Lean facility indicator recommends storing items that are not frequently used off-site.";
  }

  function brOrSpace() {
    if (windowWidth < 831) return " ";
    else return <br/>;
  }

  return (
    <div className="chart-container text-page">
      <h1 className="chart-header" style={{ maxWidth: "42ch" }}>{pageHeader}</h1>
      <div className="text-container">
        <div className="text-subcontainer">
          <p className="chart-main-text">{mainText}</p>
        </div>
      </div>
      
      {/* <p className="chart-subheader">A Lean Church needs to dedicate approx.<br/><span>{score}%</span> of its square footage to storage.</p> */}
      <p className="chart-subheader">A Lean Church needs to dedicate approx.{brOrSpace()}<strong>7%</strong> of its square footage to storage.</p>
    </div>
  );

}

export default CustomColumnCharts;



